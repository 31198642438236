<template></template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import type {
  MediaSourceTechEvent,
  MediaSourceTechEventError,
} from '@package/media-player-tech/src/events/media-source-tech-event';
import isHlsHTTPError from '@package/media-player-tech/src/tech/hls/is-hls-http-error';
import {
  ShakaErrorCategory,
  ShakaErrorCode,
  ShakaErrorSeverity,
  ShakaRuntimeError,
} from '@package/media-player-tech/src/tech/shaka/shaka-runtime-error';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { MediaErrorStatusCode, NativeMediaError } from '@package/sdk/src/core/media/media-error';
import { HlsRuntimeError } from '@PLAYER/player/errors/hls-runtime-error';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import useSafeGetMediaSourceTech from '@PLAYER/player/modules/hooks/use-safe-get-media-source-tech';
import type { ErrorData } from 'hls.js';
import { ref, watch } from 'vue';

const logger = useLogger('MediaSourceFatalErrorTrap.vue', 'media-player');
const externalEventBus = useSafeExternalEventBus();
const mediaSourceTech = useSafeGetMediaSourceTech();

const unrecoverableErrorData = ref<ErrorData | Error>();

const handleHtml5MediaError = async (error: MediaError) => {
  if (error instanceof MediaError) {
    const mediaError = new NativeMediaError(error);

    switch (mediaError.code) {
      case MediaErrorStatusCode.MEDIA_ERR_SRC_NOT_SUPPORTED:
        unrecoverableErrorData.value = mediaError;
        break;
      case MediaErrorStatusCode.MEDIA_ERR_ABORTED:
        break;
      case MediaErrorStatusCode.MEDIA_ERR_NETWORK:
      case MediaErrorStatusCode.MEDIA_ERR_DECODE:
        await mediaSourceTech.value.recoverMediaError();
        break;
    }

    externalEventBus.emit('error', mediaError);
  }
};

const handleHlsError = (data: ErrorData) => {
  const { type, fatal, details } = data;

  if (isHlsHTTPError(data)) {
    externalEventBus.emit('error', new HlsRuntimeError(data));
  } else {
    logger.error('HlsError', data);
  }

  if (!fatal) {
    return;
  }

  if (type === 'networkError' && details === 'manifestParsingError') {
    unrecoverableErrorData.value = data;
    return;
  }

  switch (type) {
    case 'networkError':
      return mediaSourceTech.value.startLoad();
    case 'mediaError':
      return mediaSourceTech.value.recoverMediaError();
    default:
      unrecoverableErrorData.value = data;
  }
};

const handleShakaError = async (error: shaka.util.Error) => {
  // Если запрос к CDN упал, или мы запутались в вызовах load/unload
  // и еще прочие странные вещи связанные с использованием плеера со стороны разработчика
  // Пока просто смотрим как часто это происходит, и трекаем это в Sentry для статистики
  if (
    error.category === ShakaErrorCategory.NETWORK ||
    error.code === ShakaErrorCode.LOAD_INTERRUPTED ||
    error.code === ShakaErrorCode.NO_VIDEO_ELEMENT ||
    error.code === ShakaErrorCode.OBJECT_DESTROYED ||
    error.code === ShakaErrorCode.OPERATION_ABORTED
  ) {
    externalEventBus.emit('error', new ShakaRuntimeError(error));
  } else {
    logger.error(error);
  }

  // Если случилась не критичная ошибка, ничего не делаем - плеер сам восстановит проигрывание
  if (error.severity === ShakaErrorSeverity.RECOVERABLE) {
    return;
  }

  // Браузер не понял, как работать с медиа-источником. По идее это будет происходить редко, и проиграть видео не получится
  // Выводим ошибку на экран
  if (
    error.code === ShakaErrorCode.UNSUPPORTED_SCHEME ||
    error.code === ShakaErrorCode.CONTENT_UNSUPPORTED_BY_BROWSER
  ) {
    unrecoverableErrorData.value = error;
    return;
  }

  // Погнали пытаться самостоятельно восстановить проигрывание
  try {
    await mediaSourceTech.value.recoverMediaError();
  } catch (error) {
    // Если у нас так и не получилось, выводим ошибку на экран
    unrecoverableErrorData.value = error;
  }
};

const onError = (event: MediaSourceTechEvent<MediaSourceTechEventError>) => {
  if (event.tech === 'html5') {
    return handleHtml5MediaError(event.originalEvent as MediaError);
  }

  if (event.tech === 'hls.js') {
    return handleHlsError(event.originalEvent as ErrorData);
  }

  if (event.tech === 'shaka') {
    return handleShakaError(event.originalEvent as shaka.util.Error);
  }

  throw new UnexpectedComponentStateError('MediaSourceFatalErrorTrap - onError');
};

watch(
  unrecoverableErrorData,
  (error) => {
    if (!error) {
      return;
    }

    if (error instanceof Error) {
      throw error;
    }

    throw new HlsRuntimeError(error);
  },
  { immediate: true },
);

useEffect(() => {
  mediaSourceTech.value.on('error', onError);

  return () => {
    mediaSourceTech.value.off('error', onError);
  };
});
</script>
