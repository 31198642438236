import useLogger from '@package/logger/src/use-logger';

const isClient = typeof window !== 'undefined';
const isServer = !isClient;

const logger = useLogger('use-local-storage');
logger.level = -1;

type DefaultLocalStorageMap = Record<string, unknown>;

export interface LocalStorageValue<TMap extends DefaultLocalStorageMap> {
  value: TMap[keyof TMap];
  expires?: number;
}

interface UseLocalStorage<TKey extends string = string, TMap extends DefaultLocalStorageMap = DefaultLocalStorageMap> {
  setValue(key: TKey, item: LocalStorageValue<TMap>): void;

  remove(key: TKey): void;

  getValue(key: TKey, defaultValue?: TMap[TKey]): LocalStorageValue<TMap> | undefined;
}

const mocks: UseLocalStorage = {
  setValue: (key: string, item: LocalStorageValue<DefaultLocalStorageMap>) => {
    logger.warn(`Calling setValue ${key} with value ${item} on server.`);
  },
  getValue: <T extends string>(
    key: T,
    item: DefaultLocalStorageMap[T],
  ): LocalStorageValue<DefaultLocalStorageMap> | undefined => {
    logger.warn(`Calling getValue ${key} with value ${item} on server.`);
    return undefined;
  },
  remove: <T extends string>(key: T) => {
    logger.warn(`Calling remove ${key}`);
  },
};

export default function useLocalStorage<
  TKey extends string = string,
  TMap extends DefaultLocalStorageMap = DefaultLocalStorageMap,
>(): UseLocalStorage<TKey, TMap> {
  /**
   * На сервере нет local-storage, поэтому возвращаем просто замоканные функции для удобства
   */
  if (isServer) {
    return mocks as UseLocalStorage<TKey, TMap>;
  }

  const remove = (key: TKey): void => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      logger.error(error);
    }
  };

  const setValue = (key: TKey, item: LocalStorageValue<TMap>) => {
    try {
      const expires = Date.now() + (item.expires || 0) * 1000;
      const stringified = JSON.stringify({ ...item, expires: item.expires ? expires : undefined });

      window.localStorage.setItem(key, stringified);
    } catch (error) {
      logger.error(error);
    }
  };

  /**
   * @description
   * Значение по умолчанию - обязательно
   */
  const getValue = (key: TKey, defaultValue?: TMap[TKey]): LocalStorageValue<TMap> | undefined => {
    try {
      const item = window.localStorage.getItem(key);

      if (!item) {
        return undefined;
      }

      const value: LocalStorageValue<TMap> = JSON.parse(item);

      // Если поле не было установлено, значит считаем что ключ хранится "вечно"
      if (!value.expires) {
        return value;
      }

      const now = Date.now();

      // Значение устарело
      if (now > value.expires) {
        remove(key);
        return undefined;
      }

      return value;
    } catch (err) {
      // Если случилась ошибка, не падаем духом - а просто возвращаем значение по умолчанию.
      logger.error(err);

      if (defaultValue) {
        return {
          value: defaultValue,
        };
      }

      return undefined;
    }
  };

  return {
    remove,
    setValue,
    getValue,
  };
}
