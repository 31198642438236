import { isDefined } from '@SDK/core';
import { isClient } from '@vueuse/core';

declare global {
  interface Window {
    // Напрямую мы с этими объетами работаем только в библиотеках hls.js / shaka. Так что корректная типизация тут не нужна.
    readonly ManagedMediaSource: unknown;
    readonly MediaSource: unknown;
  }
}

function isMediaSourceExist(): boolean {
  if (!isClient) {
    return false;
  }

  // https://bitmovin.com/blog/managed-media-source/
  return isDefined(window.ManagedMediaSource) || isDefined(window.MediaSource);
}

export default function useManagedMediaSourceSupport() {
  return isMediaSourceExist();
}
