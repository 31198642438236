import type { Moment } from '@package/sdk/src/api';
import { isDefined } from '@package/sdk/src/core';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { computed } from 'vue';

import usePlaylistStore from '../store/playlist-store';
import useContentInformation from './use-content-information';

export default function useContentLinkedKinom() {
  const videoConfig = useVideoConfig();

  const linkedKinom = computed(() => videoConfig['content.linkedKinom'] as Moment);
  const hasLinkedKinom = computed(() => isDefined(linkedKinom.value));

  const linkedKinomStartOffset = computed(() => linkedKinom.value?.startOffset);
  const linkedKinomDuration = computed(() => linkedKinom.value?.duration);

  const { isSerialContent } = useContentInformation();
  const { currentPlaylistItem } = usePlaylistStore();

  const isLinkedKinomTargetContent = computed(() => {
    if (!isSerialContent.value) {
      return true;
    }

    return (
      currentPlaylistItem?.watchingItem?.episodeNumber === linkedKinom.value?.episodeNumber &&
      currentPlaylistItem?.watchingItem?.seasonNumber === linkedKinom.value?.seasonNumber
    );
  });

  const clearLinkedKinom = () => {
    if (hasLinkedKinom.value) {
      videoConfig['content.linkedKinom'] = undefined;
    }
  };

  return {
    linkedKinom,
    linkedKinomStartOffset,
    linkedKinomDuration,
    hasLinkedKinom,
    isLinkedKinomTargetContent,
    clearLinkedKinom,
  };
}
