<template>
  <Transition>
    <section :class="$style.toolbar">
      <NavigatableItem
        :tag="UIButton"
        :extra-props="extraProps"
        :class="{
          [$style.button]: true,
          [$style.toolbarButton]: true,
          [$style.selected]: isCurrentVideoLiked,
        }"
        :active-class="$style.active"
        @active="$emit('active')"
        @click="onLike"
      >
        <template #icon>
          <ThumbUpIcon :class="$style.icon" />
        </template>
      </NavigatableItem>
      <NavigatableItem
        :tag="UIButton"
        :extra-props="extraProps"
        :class="{
          [$style.button]: true,
          [$style.toolbarButton]: true,
          [$style.selected]: isCurrentVideoDisliked,
        }"
        :active-class="$style.active"
        @active="$emit('active')"
        @click="onDislike"
      >
        <template #icon>
          <ThumbDownIcon :class="$style.icon" />
        </template>
      </NavigatableItem>
      <NavigatableItem
        :tag="UIButton"
        :extra-props="extraProps"
        :class="{
          [$style.button]: true,
          [$style.bookmarkButton]: true,
          [$style.selected]: isCurrentVideoSaved,
        }"
        :active-class="$style.active"
        @active="$emit('active')"
        @click="onSave"
      >
        <template #icon>
          <BookmarkFillIcon v-if="isCurrentVideoSaved" :class="$style.bookMarkSavedIcon" />
          <BookmarkIcon v-else :class="$style.bookMarkIcon" />
        </template>
      </NavigatableItem>
    </section>
  </Transition>
</template>

<script setup lang="ts">
import type { Moment } from '@package/sdk/src/api';
import { LikeState } from '@package/sdk/src/api';
import { throttleWithImmediate } from '@package/sdk/src/core';
import BookmarkIcon from '@SMART/assets/icons/51x51/bookmark.svg';
import BookmarkFillIcon from '@SMART/assets/icons/51x51/save.svg';
import ThumbDownIcon from '@SMART/assets/icons/51x51/thumbs-down.svg';
import ThumbUpIcon from '@SMART/assets/icons/51x51/thumbs-up.svg';
import { collectionService } from '@SMART/index';
import { computed } from 'vue';

import UIButton from '@/components/button/UIButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

const CLICK_TIMEOUT_MS = 1_500;

interface Props {
  moment: Moment;
  extraProps?: Record<string, any>;
}
const props = defineProps<Props>();

const emit = defineEmits(['like', 'active', 'save']);

const onLike = throttleWithImmediate(() => emit('like', LikeState.Like), {
  timeout: CLICK_TIMEOUT_MS,
  immediate: true,
});
const onDislike = throttleWithImmediate(() => emit('like', LikeState.Dislike), {
  timeout: CLICK_TIMEOUT_MS,
  immediate: true,
});
const onSave = throttleWithImmediate(() => emit('save'), { timeout: CLICK_TIMEOUT_MS, immediate: true });

const currentLikeState = computed(() => props.moment?.likeState);
const isCurrentVideoLiked = computed(() => currentLikeState.value === LikeState.Like);
const isCurrentVideoDisliked = computed(() => currentLikeState.value === LikeState.Dislike);
const isCurrentVideoSaved = computed(
  () => props.moment?.inUserCollection || collectionService.savedMomentsItems?.includes(props.moment?.id),
);
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.toolbar {
  position: absolute;
  bottom: adjustPx(48px);
  right: adjustPx(48px);
  display: flex;
  flex-flow: row;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 adjustPx(24px);
  width: adjustPx(96px);
  height: adjustPx(96px);
  border: none;
  border-radius: adjustPx(24px);

  &:hover {
    background: var(--color-bg-accent);
    color: var(--color-notheme-text-accent);

    .bookMarkIcon {
      width: adjustPx(51px);
      height: adjustPx(51px);
      fill: var(--color-notheme-text-accent);
    }
  }
}

.toolbarButton {
  margin-right: adjustPx(16px);
  background-color: var(--color-bg-secondary-80);
}

.bookmarkButton {
  background-color: var(--color-bg-secondary-80);
}

.active {
  background-color: var(--color-bg-accent);

  .icon {
    color: var(--color-notheme-text-accent);
    fill: var(--color-bg-accent);
  }

  .bookMarkIcon {
    width: adjustPx(51px);
    height: adjustPx(51px);
    fill: var(--color-notheme-text-accent);
  }
}

.selected {
  .icon {
    color: var(--color-states-bg-secondary-disabled);
    fill: var(--color-text-primary);
  }

  &:hover {
    background-color: var(--color-bg-accent);
    .icon {
      color: var(--color-bg-accent);
      fill: var(--color-text-primary);
    }
  }
  &.active {
    background-color: var(--color-bg-accent);

    .icon {
      color: var(--color-bg-accent);
      fill: var(--color-notheme-text-accent);
    }

    .bookMarkSavedIcon {
      path {
        fill: var(--color-notheme-text-accent);
        stroke: var(--color-notheme-text-accent);
      }
    }
  }
}
.bookMarkIcon {
  width: adjustPx(51px);
  height: adjustPx(51px);
  fill: var(--color-text-primary);
}
</style>
