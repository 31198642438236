import { AnalyticEventNameNew, AnalyticPayloadLabelName } from '@package/sdk/src/analytics';
import { roundNumber, type TimeSeconds } from '@PLAYER/player/base/number';
import { type AnalyticValue } from '@PLAYER/player/modules/analytics/analytics';
import useAnalytics from '@PLAYER/player/modules/analytics/use-analytics';
import usePlaybackAnalytics from '@PLAYER/player/modules/analytics/use-skip-playback-analytics';
import useTimelineAnalytics from '@PLAYER/player/modules/analytics/use-timeline-analytics';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import useRemoteControlEvent from '@PLAYER/player/versions/smart/modules/remote-control/use-remote-control-event';
import { storeToRefs } from 'pinia';

interface PlaybackPlayPauseOptions {
  manual?: boolean;
  type?: 'click' | 'intersect';
}

interface PlaybackSeekToOptions {
  offset: TimeSeconds;
  coldStart?: boolean;
  manual?: boolean;
}

const DEFAULT_PLAYBACK_OPTIONS: PlaybackPlayPauseOptions = {
  manual: true,
  type: 'click',
};

export default function usePlaybackActions() {
  const videoControlsStore = useVideoControlsStore();
  const { isPlaying, displayedCurrentTime } = storeToRefs(useVideoUIStore());
  const { normalizedDisplayedCurrentTime } = useVideoPlayerVariables();
  const { sendAnalytic } = useAnalytics();
  const { isKinom, isVOD } = useProjector();
  const { triggerRemoteControlEvent } = useRemoteControlEvent();

  const playbackAnalytics = usePlaybackAnalytics();
  const timelineAnalytics = useTimelineAnalytics();

  const videoInteractions = useVideoInteractions();

  const getNormalizedCurrentTime = () => roundNumber(normalizedDisplayedCurrentTime.value);

  const seekTo = (options: PlaybackSeekToOptions) => {
    const { offset, coldStart = false, manual = true } = options;

    if (coldStart) {
      playbackAnalytics.setStartTimeStreaming(offset);
    } else {
      timelineAnalytics.clickPlayerTimeSet(offset, getNormalizedCurrentTime());
      playbackAnalytics.setStartTimeStreaming(offset);
    }

    videoControlsStore.setIsShouldPlayVideoAfterRewind(isPlaying.value);

    videoInteractions.changeCurrentTime({
      seconds: offset,
      manually: manual,
    });
  };

  const doPlay = (options: PlaybackPlayPauseOptions = DEFAULT_PLAYBACK_OPTIONS) => {
    const { manual = true, type = 'click' } = options;

    if (manual) {
      const values: AnalyticValue[] = [];

      if (isKinom.value || isVOD.value) {
        values.push({ label: AnalyticPayloadLabelName.ViewingTime, value: getNormalizedCurrentTime() });
      }

      const eventName = (() => {
        if (isKinom.value) {
          return type === 'click' ? AnalyticEventNameNew.ClickKinomPlay : AnalyticEventNameNew.AutoKinomPlay;
        }

        return AnalyticEventNameNew.ClickPlayerPlay;
      })();

      sendAnalytic({ name: eventName, values });
    }

    videoInteractions.play();

    if (manual) {
      triggerRemoteControlEvent({
        paused: false,
        offset: displayedCurrentTime.value,
      });
    }
  };

  const doPause = (options: PlaybackPlayPauseOptions = DEFAULT_PLAYBACK_OPTIONS) => {
    const { manual, type } = options;

    if (manual) {
      const values: AnalyticValue[] = [];

      if (isKinom.value || isVOD.value) {
        values.push({ label: AnalyticPayloadLabelName.ViewingTime, value: getNormalizedCurrentTime() });
      }

      const eventName = (() => {
        if (isKinom.value) {
          return type === 'click' ? AnalyticEventNameNew.ClickKinomPause : AnalyticEventNameNew.AutoKinomPause;
        }

        return AnalyticEventNameNew.ClickPlayerPause;
      })();

      sendAnalytic({ name: eventName, values });
    }

    videoInteractions.pause();

    if (manual) {
      triggerRemoteControlEvent({
        paused: true,
        offset: displayedCurrentTime.value,
      });
    }
  };

  const togglePlayback = () => {
    const isMediaPlaying = isPlaying.value;

    videoControlsStore.setCurrentAction({
      type: isMediaPlaying ? 'pause' : 'play',
    });

    return isMediaPlaying ? doPause() : doPlay();
  };

  return {
    doPlay,
    doPause,
    togglePlayback,
    seekTo,
  };
}
