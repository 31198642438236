<template>
  <div :class="$style.checkbox">
    <div :class="$style.wrapper">
      <input
        :id="id"
        type="checkbox"
        :class="$style.input"
        :checked="input"
        @input="(event) => emit('update:input', Boolean(event.target.checked))"
      />
      <label :class="$style.label" :for="id"></label>
    </div>
    <span :class="$style.text">
      {{ text }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { ulid } from 'ulid';

defineProps<{
  text: string;
  input?: boolean;
}>();

const id = ulid();
const emit = defineEmits(['update:input']);
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as webFonts;

.checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
}

.input {
  width: 0;
  height: 0;
  outline: none;
  opacity: 0;

  &:checked + label {
    background: var(--color-notheme-bg-accent);
  }

  &:checked + label::after {
    left: 100%;
    transform: translateX(-100%);
  }
}

.wrapper {
  display: flex;
}

.text {
  @include webFonts.WebLabel-3();
}

.label {
  position: relative;
  width: 34px;
  height: 14px;
  border-radius: 100px;
  background-color: var(--color-text-secondary);
  transition: background-color 0.3s;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: -3px;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 90px;
    background-color: var(--color-notheme-text-primary);
    transition: all 0.3s;
  }
}
</style>
