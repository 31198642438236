import { ParentalControlStatus, SubscriptionPlatform } from '@package/sdk/src/api';
import {
  globalSettings,
  type SessionGetters,
  type SessionState,
  storeToRefs,
  useSessionStore,
} from '@package/smarttv-base/src';

export default function useSessionVariables() {
  const { _user, isPartnerSubscription, subscription, isPartnerUser } = storeToRefs<
    SessionState,
    SessionGetters,
    unknown
  >(useSessionStore());

  const isAuth = globalSettings.computed(() => Boolean(_user?.value));

  const isActiveSubscription = globalSettings.computed(() => _user?.value?.subscription?.active);

  const isChildProfileSet = globalSettings.computed(
    () => _user?.value?.currentDevice.parentalControlStatus !== ParentalControlStatus.NotSet,
  );

  const currentUserPlatform = globalSettings.computed(() => subscription.value?.platform);

  const isAppleSubscription = globalSettings.computed(() => currentUserPlatform.value === SubscriptionPlatform.Apple);
  const isGoogleSubscription = globalSettings.computed(() => currentUserPlatform.value === SubscriptionPlatform.Google);
  const isSberSubscription = globalSettings.computed(() => currentUserPlatform.value === SubscriptionPlatform.Sber);

  const isInactivePartnerSubscription = globalSettings.computed(
    () => isPartnerSubscription.value && !isActiveSubscription.value,
  );

  const isDeviceParentalControlSet = globalSettings.computed(
    () => _user?.value?.currentDevice.parentalControlStatus !== ParentalControlStatus.NotSet,
  );

  const isExternalSubscription = globalSettings.computed(
    () =>
      isAppleSubscription.value ||
      isGoogleSubscription.value ||
      isSberSubscription.value ||
      isPartnerSubscription.value,
  );

  return {
    isChildProfileSet,
    isActiveSubscription,
    isInactivePartnerSubscription,
    isDeviceParentalControlSet,
    isPartnerUser,
    isExternalSubscription,
    isAppleSubscription,
    isGoogleSubscription,
    isAuth,
  };
}
