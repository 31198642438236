// @ts-nocheck

import useLogger from '@package/logger/src/use-logger';
import { isUndefinedOrNull, toDisposable } from '@SDK/core';

import { AbstractMediaAnalyzer, MediaAnalyzerEvent } from './abstract-media-analyzer';

const logger = useLogger('ShakaPlayerAnalyzer');

/**
 *
 * @see https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html
 */
const CRITICAL_CODE_ERRORS = [1002, 3016, 4016, 7002];

interface ShakaAdaptationEvent extends Event {
  oldTrack: shaka.extern.Track | null;
  newTrack: shaka.extern.Track;
}

export class ShakaPlayerAnalyzer extends AbstractMediaAnalyzer {
  constructor(private readonly shaka: shaka.Player) {
    super();

    this.registerListeners();
  }

  private registerListeners() {
    const onError = (error: Event) => {
      const normalizedError = error as unknown as shaka.util.Error;

      // Какая-то другая ошибка
      if (isUndefinedOrNull(normalizedError?.code)) {
        return;
      }

      if (CRITICAL_CODE_ERRORS.includes(normalizedError.code)) {
        this.emitter.emit('playback-failed', new MediaAnalyzerEvent<'playback-failed'>());
      }
    };

    const onStallDetected = () => {
      console.error('stalled');
      this.emitter.emit('playback-interrupted', new MediaAnalyzerEvent<'playback-interrupted'>());
    };

    const onAdaptationChanged = (event: Event) => {
      const levels = this.shaka.getVariantTracks();

      const currentLevel = levels.find((level) => level.active);

      if (!currentLevel) {
        return;
      }

      const { oldTrack, newTrack } = event as ShakaAdaptationEvent;

      /**
       * Stall detected иногда работает странно, и не эмиттитится, если соединение замедлилось (и чанки не выгружаются)
       * Но у shaka есть событие `adaptation`, которое эмитится, если качество поменялось автоматически.
       * Смотрим на это событие, и если качество поменялось в худшую сторону - значит есть проблемы
       */
      if (oldTrack?.height && newTrack.height) {
        const newLevelHeight = newTrack.height;
        const oldLevelHeight = oldTrack.height;

        if (newLevelHeight < oldLevelHeight) {
          this.emitter.emit('playback-interrupted', new MediaAnalyzerEvent<'playback-interrupted'>());
        }
      }
    };

    this.shaka.addEventListener('error', onError);
    this.shaka.addEventListener('stalldetected', onStallDetected);
    this.shaka.addEventListener('adaptation', onAdaptationChanged);

    this.disposableStore.add(
      toDisposable(() => {
        this.shaka.removeEventListener('error', onError);
        this.shaka.removeEventListener('stalldetected', onStallDetected);
        this.shaka.removeEventListener('adaptation', onAdaptationChanged);
      }),
    );
  }

  public override dispose() {
    this.disposableStore.dispose();
  }
}
