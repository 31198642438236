import { EventEmitter } from '@package/sdk/src/core';

import type { DsmlEventError } from '../platform/errors';

export interface DsmlEventMap {
  error: DsmlEventError;
}

const emitter = new EventEmitter<DsmlEventMap>();

export default emitter;
