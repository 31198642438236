<template>
  <div
    ref="handleEl"
    :class="$style.handle"
    :style="{ left: `calc(${$toPercent(normalizedHandlePosition * 100)} - ${$toPixel((isSmartTV ? 16 : width) / 2)})` }"
  />
</template>

<script lang="ts" setup>
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import { useElementSize } from '@vueuse/core';
import { computed, onMounted, ref } from 'vue';

const props = defineProps<{
  currentHandlePosition: number;
}>();

const normalizedHandlePosition = computed(() => {
  if (props.currentHandlePosition <= 0) {
    return 0;
  }

  if (props.currentHandlePosition >= 1) {
    return 1;
  }

  return props.currentHandlePosition;
});

const handleEl = ref<HTMLElement>();

const { width } = useElementSize(handleEl);
const { isSmartTV } = usePlatform();
const videoControlsStore = useVideoControlsStore();

if (isSmartTV) {
  onMounted(() => {
    if (!handleEl.value) {
      throw new UnexpectedComponentStateError('handleEl');
    }

    videoControlsStore.setTimelineBarHandleEl(handleEl.value);

    // Для смартов - обязательно выставляем tabindex (требуется). На вебе он не нужен
    handleEl.value.setAttribute('tabindex', '0');

    window.$setActiveNavigationItem(handleEl.value);
  });
}
</script>

<style lang="scss" module>
.handle {
  --handle-bottom-position: -3px;
  position: absolute;
  bottom: var(--handle-bottom-position);

  will-change: left;
  cursor: pointer;
}

.handleAnimated {
  transition: linear 50ms;
}
</style>
