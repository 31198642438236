import AbstractMediaSourceTech, {
  type MediaSourceLoadOptions,
  type MediaSourceTechBufferInfo,
} from '@package/media-player-tech/src/media-source-tech';

class TataudioMediaInstance {
  public readonly mediaSource: MediaSource;

  constructor() {
    this.mediaSource = new MediaSource();
  }
}

export default class TataudioMediaSourceTech extends AbstractMediaSourceTech {
  private tech: TataudioMediaInstance;

  constructor() {
    super();

    this.tech = new TataudioMediaInstance();
  }

  public get audioCodec(): string {
    return '';
  }

  public get bandwidth(): number {
    return 0;
  }

  public get buffer(): MediaSourceTechBufferInfo {
    return {
      length: 0,
      start: 0,
    };
  }

  public get currentQualityLevelHeight(): number {
    return 0;
  }

  public getNextLevel(): number {
    return 0;
  }

  public init(): Promise<void> {
    return Promise.resolve(undefined);
  }

  public get latency(): number {
    return 0;
  }

  public async loadSource(_: MediaSourceLoadOptions): Promise<void> {
    //
  }

  public recoverMediaError(): Promise<void> {
    return Promise.resolve(undefined);
  }

  protected registerListeners(): void {
    //
  }

  public setNextLevel(_: number): void {
    //
  }

  public requestSaveMediaOffline(_: string): Promise<void> {
    return Promise.resolve(undefined);
  }

  public async clearOfflineCache(): Promise<void> {}

  public startLoad(_: number | undefined): void {
    //
  }

  public async stopLoad(): Promise<void> {
    //
  }

  public get videoCodec(): string {
    return '';
  }

  public pause(): void {
    this.videoEl.pause();
  }

  public play(): Promise<void> {
    return this.videoEl.play();
  }

  public seekTo(offset: number): void {
    this.videoEl.currentTime = offset;
  }
}
