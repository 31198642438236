import type { VijuPlayer } from '@PLAYER/player/modules/instance/player';
import type { Moment } from '@SDK/api';
import type { Ref } from 'vue';
import { watch } from 'vue';

interface Options {
  player: VijuPlayer;
  moments: Ref<readonly Moment[]>;
  currentMomentIndex: Ref<number>;
}

export default function useOfflineMomentsPlayback(options: Options) {
  const { player, moments, currentMomentIndex } = options;

  watch(
    currentMomentIndex,
    (index) => {
      const nextMoment = moments.value[index + 1];

      if (!nextMoment?.hls) {
        return;
      }

      player.requestSaveMediaOffline(nextMoment.hls);
    },
    { immediate: true },
  );
}
