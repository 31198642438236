<template>
  <component
    :is="timelineComponent"
    :ref="(comp) => (timelineEl = comp?.$el)"
    :class="$style.background"
    :bar-class-name="$style.default"
    :pixel-factor="pixelFactor"
    :chapters="chapters"
    role="slider"
    :is-handle-focused="isHandleFocused"
    :aria-label="$t('controls.elements.timeline')"
    aria-valuemin="0"
    :aria-valuemax="Math.round(duration)"
    :aria-valuenow="Math.round(currentTime)"
  >
    <transition :name="transitionName">
      <timeline-bar-handle
        v-if="isTimelineHandleShown"
        :ref="(comp) => (handleEl = comp?.$el)"
        :class="{
          [$style.handle]: true,
          [$style.handleSmartTv]: isSmartTV,
          [$style.handleSmartTvFocus]: timelineFocused,
        }"
        :current-handle-position="currentHandlePosition"
      />
    </transition>
    <transition :name="transitionName">
      <timeline-bar-handle
        v-if="isTimelineHandleHoverShown"
        :class="{
          [$style.handle]: true,
          [$style.handleHover]: true,
        }"
        :current-handle-position="currentHandleHoverPosition"
      />
    </transition>
  </component>
</template>

<script lang="ts" setup>
import { isDesktop } from '@PLAYER/player/base/dom';
import TimelineBarChapter from '@PLAYER/player/components/timeline/TimelineBarChapter.vue';
import TimelineBarDefault from '@PLAYER/player/components/timeline/TimelineBarDefault.vue';
import TimelineBarHandle from '@PLAYER/player/components/timeline/TimelineBarHandle.vue';
import useTransitionName from '@PLAYER/player/modules/animation/use-transition-name';
import useVideoChapters from '@PLAYER/player/modules/content/use-video-chapters';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import type { TimelineChapter } from '@PLAYER/player/modules/timeline/use-timeline-chapters';
import { useFocus } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, inject, ref } from 'vue';

const props = defineProps<{
  chapters: TimelineChapter[];
  isTimelineHoverHandleShown: boolean;
  currentHandlePosition: number;
  isMouseOver: boolean;
  pixelFactor: number;
  currentTime: number;
  duration: number;
  currentHandleHoverPosition: number;
}>();

const { transitionName } = useTransitionName();
const { isMyChannelPlayer, isKinom } = useProjector();
const { isChaptersPresent } = useVideoChapters();
const { isSmartTV } = usePlatform();
const { isRewindTriggeredByUserControls, isRewindTipNotificationShown } = storeToRefs(useVideoControlsStore());

const timelineEl = ref<HTMLElement>();
const handleEl = ref<HTMLElement>();

const timelineFocused = inject('timelineFocused', false);

const isTimelineHandleShown = computed(() => {
  if (isSmartTV) {
    return !isKinom.value;
  }

  return props.isMouseOver || isRewindTriggeredByUserControls.value || isRewindTipNotificationShown.value;
});

const isTimelineHandleHoverShown = computed(() => {
  if (isMyChannelPlayer.value) {
    return false;
  }

  return (
    props.isTimelineHoverHandleShown &&
    isTimelineHandleShown.value &&
    isDesktop &&
    !isRewindTriggeredByUserControls.value
  );
});

const { focused: isHandleFocused } = useFocus(handleEl);

defineExpose({
  isHandleFocused,
});

const timelineComponent = computed(() => (isChaptersPresent.value ? TimelineBarChapter : TimelineBarDefault));
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.background {
  position: relative;
  cursor: pointer;
}

.default {
  height: 4px;
}

.handle {
  left: 0;
  z-index: 2;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  outline: 0;
  background-color: var(--color-notheme-bg-accent);
  background-clip: content-box;

  &:active {
    pointer-events: none;
  }
}

.handleHover {
  background-color: var(--color-notheme-icon-primary);

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-color: var(--color-notheme-icon-primary);
  }
}

.handleSmartTv {
  top: adjust.adjustPx(-10px);
  width: adjust.adjustPx(24px);
  height: adjust.adjustPx(24px);
  border: adjust.adjustPx(8px) solid var(--color-notheme-bg-secondary-80);
  background-color: var(--color-notheme-bg-secondary-80);

  &:hover:not([disabled]) {
    border: adjust.adjustPx(8px) solid var(--color-notheme-bg-accent);
  }
}

.handleSmartTvFocus {
  border: adjust.adjustPx(8px) solid var(--color-notheme-bg-accent);
}
</style>
