type StorageKey =
  | 'eventSenderMode'
  | 'osVersion'
  | 'browserVersion'
  | 'deviceType'
  | 'appVariation'
  | 'featureFlags'
  | 'debugMode'
  | 'appVersion'
  | 'utmValues'
  | 'env'
  | 'password'
  | 'token'
  | 'clientType'
  | 'partnerId'
  | 'userId'
  | 'profileId'
  | 'userIpV4'
  | 'userIpV6'
  | 'sessionId'
  | 'visitorId'
  | 'storageType';

class MemoryStorage {
  private readonly _map = new Map<StorageKey, unknown>();

  private _get(key: StorageKey): unknown {
    return this._map.get(key);
  }

  public get<T = unknown>(key: StorageKey): T | undefined {
    return this._get(key) as T;
  }

  public has(key: StorageKey): boolean {
    return this._map.has(key);
  }

  public set(key: StorageKey, value: unknown): void {
    this._map.set(key, value);
  }

  public getAll() {
    const client_type = this._get('clientType');
    const partner_id = this._get('partnerId');

    const user_id = this._get('userId');
    const profile_id = this._get('profileId');

    const session_id = this._get('sessionId');
    const visitor_id = this._get('visitorId');

    const ipV4 = this._get('userIpV4');
    const ipV6 = this._get('userIpV6');

    const ip = ipV4 ? 'user_ip_v4' : 'user_ip_v6';

    const app_version = this._get('appVersion');
    const device_type = this._get('deviceType');

    const os_version = this._get('osVersion');
    const browser_version = this._get('browserVersion');

    return {
      [ip]: ipV4 || ipV6,
      os_version,
      browser_version,
      user_id,
      profile_id,
      session_id,
      visitor_id,
      client_type,
      device_type,
      partner_id,
      app_version,
    };
  }
}

const memoryStorage = new MemoryStorage();

export default memoryStorage;
