import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import useState from '@PLAYER/player/modules/global/use-state';
import { computed, type Ref } from 'vue';

export default function useMousePositionDetection(el: Ref<HTMLElement | undefined>) {
  const [mouseXFraction, setMouseXFraction] = useState<number | undefined>(undefined);

  useEffect(() => {
    const onMousemove = (event: MouseEvent) => {
      const { currentTarget, pageX } = event;

      if (!currentTarget || !(currentTarget instanceof window.HTMLElement)) {
        throw new UnexpectedComponentStateError('useMoveDetection');
      }

      const targetRect = currentTarget.getBoundingClientRect();
      const mouseMoveXFraction = Math.max(0, (pageX - targetRect.left) / targetRect.width);

      setMouseXFraction(mouseMoveXFraction);
    };

    const onMouseleave = () => {
      setMouseXFraction(undefined);
    };

    el.value?.addEventListener('mousemove', onMousemove);
    el.value?.addEventListener('mouseleave', onMouseleave);

    return () => {
      el.value?.removeEventListener('mousemove', onMousemove);
      el.value?.removeEventListener('mouseleave', onMouseleave);
    };
  });

  const normalizedMouseXFraction = computed(() => {
    if (!mouseXFraction.value) {
      return undefined;
    }

    return Math.max(0, Math.min(1, mouseXFraction.value));
  });

  return {
    mouseXFraction,
    normalizedMouseXFraction,
  };
}
