import { isDefined, isNumberInRange, isTrue, isUndefined, timeout } from '@package/sdk/src/core';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useDrag from '@PLAYER/player/modules/mouse/use-drag';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useRootVideoElBounds from '@PLAYER/player/modules/video/use-root-video-el-bounds';
import { storeToRefs } from 'pinia';
import { computed, onMounted, type Ref, ref, watch } from 'vue';

interface UseVideoTimelineOptions {
  onStartCallback?: (event: MouseEvent | TouchEvent) => void;
  onEndCallback?: (event: MouseEvent | TouchEvent) => void;
  duration: Ref<number>;
  isPlaying: Ref<boolean>;
  currentTime: Ref<number>;
  isLongTapRewind?: boolean;

  pause(): void;
  play(): void;
  changeCurrentTime(val: number): void;
}

const safeFraction = (value: number) => Math.max(0, Math.min(1, value));

export default function useVideoTimeline(timelineEl: Ref<HTMLElement | undefined>, options: UseVideoTimelineOptions) {
  const { isWeb } = usePlatform();
  const { appHeight } = useRootVideoElBounds();
  const videoControlsStore = useVideoControlsStore();

  const { isShouldPlayVideoAfterRewind } = storeToRefs(videoControlsStore);

  const {
    duration,
    isPlaying,
    currentTime,
    onEndCallback,
    onStartCallback,
    pause,
    play,
    changeCurrentTime,
    isLongTapRewind,
  } = options;

  const {
    isDragging,
    mouseXFraction: dragMouseXFraction,
    mouseYPixelDiff,
    isSingleTouch,
  } = useDrag(timelineEl, {
    onEndCallback,
    onStartCallback,
    isLongTapRewind,
  });

  const pauseVideoWhenDragging = () => {
    pause();

    if (isPlaying.value) {
      videoControlsStore.setIsShouldPlayVideoAfterRewind(true);
    }
  };

  const unpauseVideoAfterDragging = async () => {
    if (!isTrue(isShouldPlayVideoAfterRewind.value)) {
      return;
    }

    play();
    videoControlsStore.setIsShouldPlayVideoAfterRewind(undefined);
  };

  const speedSlidingLevels = computed(() => {
    const arr = [1, 2, 3].map((delimeter) => (appHeight.value - 60) / delimeter).reverse();
    arr.unshift(0);

    return arr;
  });

  const currentSlidingSpeed = ref(0);

  const normalizedDragMouseXFraction = ref<number | undefined>(undefined);

  const lastTimeChangedManually = ref(0);

  const currentHandlePosition = computed(() => {
    if (isDefined(normalizedDragMouseXFraction.value)) {
      return safeFraction(normalizedDragMouseXFraction.value);
    }

    return currentTime.value / duration.value;
  });

  watch(dragMouseXFraction, (value, oldValue) => {
    if (!value) {
      normalizedDragMouseXFraction.value = undefined;
      return;
    }

    if (!normalizedDragMouseXFraction.value) {
      normalizedDragMouseXFraction.value = value;
      return;
    }

    if (!oldValue) {
      return;
    }

    const speedMultiplier = (() => {
      if (currentSlidingSpeed.value === 0) {
        return 0;
      }

      if (currentSlidingSpeed.value === 1) {
        return 5;
      }

      return 250;
    })();

    const diffValue = value - oldValue;

    if (!speedMultiplier) {
      normalizedDragMouseXFraction.value += diffValue;
      return;
    }

    normalizedDragMouseXFraction.value += diffValue / speedMultiplier;
  });

  watch(isDragging, (value) => {
    if (!value && isLongTapRewind) {
      if (isSingleTouch.value) {
        return;
      }
    }

    if (value && !isLongTapRewind) {
      return pauseVideoWhenDragging();
    }

    unpauseVideoAfterDragging();
  });

  watch(normalizedDragMouseXFraction, async (fraction?: number) => {
    if (!fraction) {
      return;
    }

    const newTime = safeFraction(fraction) * duration.value;

    lastTimeChangedManually.value = newTime;
    // Нужно, чтобы другие средства аналитики успели подписаться.
    await timeout(0);
    changeCurrentTime(newTime);
  });

  if (isWeb) {
    watch(mouseYPixelDiff, (y) => {
      if (isUndefined(y) || Number.isNaN(y)) {
        return;
      }

      const normalizedY = y * -1;

      if (normalizedY <= 25) {
        currentSlidingSpeed.value = 0;
        return;
      }

      speedSlidingLevels.value.forEach((level, index) => {
        const nextLevel = speedSlidingLevels.value[index + 1];

        if (!nextLevel) {
          return;
        }

        const inRange = isNumberInRange(normalizedY, level, nextLevel);

        if (inRange) {
          currentSlidingSpeed.value = index;
        }
      });
    });
    onMounted(() => videoControlsStore.setIsShouldPlayVideoAfterRewind(true));
  }

  return {
    normalizedDragMouseXFraction,
    isDragging,
    lastTimeChangedManually,
    currentHandlePosition,
    mouseYPixelDiff,
  };
}
