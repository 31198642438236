<template>
  <section ref="container" :class="$style.wrapper">
    <h2 :class="$style.title">{{ title }}</h2>

    <div ref="listContainer">
      <ScrollViewport tag="ul" :class="$style.list" :x="offsetLeftPx" role="list">
        <li v-for="(item, index) in normalizedBlocks" :key="item.id" :class="[$style.item, $style[type]]">
          <NavigatableItem
            :class="$style.link"
            :tag="AppSlotButton"
            :data-testid="`media-${index + 1}`"
            :data-index="index"
            :focus-key="FocusKeys.MEDIA_CARD_SLIDER_ITEM(props.type.toUpperCase(), index)"
            @active="(element) => handleActiveItem(item as Media, index, element)"
            @click="$emit('selected', { item, index })"
          >
            <app-image
              v-if="(item as Media).poster"
              :src="(item as Media).poster"
              :class="$style.preview"
              :width="550"
            />
            <app-image
              v-else-if="(item as Media).preview"
              :src="(item as Media).preview"
              :class="$style.preview"
              :width="550"
            />
            <PlayIcon v-if="type === 'moment' && activeItem?.id === item.id" :class="$style.playIcon" />
            <p v-else :class="$style.actor">{{ getFirstLetters((item as AbstractPerson).name) }}</p>
          </NavigatableItem>
          <section v-if="type === 'person' && (item as AbstractPerson).name" :class="$style.subtitle">
            <p>{{ (item as AbstractPerson).name }}</p>
            <p :class="$style.role">{{ (item as AbstractPerson).role }}</p>
          </section>
          <section v-else-if="type === 'moment' && (item as Moment).title" :class="$style.subtitle">
            <p>{{ (item as Media).title }}</p>
          </section>
          <section v-else-if="type === 'poster' && (item as Media).title" :class="$style.subtitle">
            <p>{{ (item as Media).title }}</p>
          </section>
        </li>
      </ScrollViewport>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { AbstractPerson, Media, Moment } from '@package/sdk/src/api';
import useListNavigationActions, {
  type ScrollDirection,
} from '@package/smarttv-base/src/navigation/use-list-navigation-actions';
import { useLazyLoadingBlocks } from '@package/smarttv-base/src/utils/use-lazy-loading-blocks';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import PlayIcon from '@SMART/assets/icons/96x96/play.svg';
import { FocusKeys, useSliderOffset } from '@SMART/index';
import { computed, provide, ref } from 'vue';

import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

interface Props {
  items: Media[] | Moment[] | AbstractPerson[];
  title: string;
  type: 'moment' | 'poster' | 'person' | 'series';
}

const props = defineProps<Props>();

const { focusKey, el: container } = useNavigatable({
  focusKey: FocusKeys.MEDIA_CARD_SLIDER(props.type.toUpperCase()),
  saveLastFocusedChild: true,
  hasGlobalAccess: true,
  onBlur: () => {
    activeItem.value = undefined;
  },
  onFocus: () => {
    lastFocusKey.value = null;
    emit('focused');
  },
});
provide('parentFocusKey', focusKey.value);

const sliderList = useListNavigationActions((index) =>
  FocusKeys.MEDIA_CARD_SLIDER_ITEM(props.type.toUpperCase(), index),
);

export interface SelectedMediaPayload {
  item: Media | Moment | AbstractPerson;
  index: number;
}

const emit = defineEmits<{
  (event: 'activated', offset: number, item: Media): void;
  (event: 'selected', payload: SelectedMediaPayload): void;
  (event: 'focused'): void;
  (event: 'scrolled', direction: ScrollDirection, item: Media): void;
}>();

const { handleUpdateOffset, offsetLeftPx } = useSliderOffset();
const listContainer = ref<HTMLElement>();
const activeItem = ref<Media>();

const moments = computed(() => props.items);

const { normalizedBlocks, onVNodeFocused } = useLazyLoadingBlocks({
  items: moments,
  startIndex: 1,
  indexSpread: 4,
});

const lastFocusKey = ref();

const handleActiveItem = (item: Media, index: number, element: HTMLElement) => {
  activeItem.value = item;
  onVNodeFocused(index);

  handleUpdateOffset(element, listContainer.value?.offsetWidth);

  if (lastFocusKey.value && offsetLeftPx.value) {
    emit('scrolled', sliderList.getScrollDirection(lastFocusKey.value), item);
  }
  lastFocusKey.value = SpatialNavigation.getCurrentFocusKey();

  emit('activated', container.value?.offsetTop || 0, item);
};

const getFirstLetters = (text = '') =>
  text
    .split(' ')
    .map((x) => x[0])
    .join('')
    .toUpperCase();
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.title {
  margin-bottom: adjust.adjustPx(32px);

  @include smartTvFonts.SmartTvSubtitle-1();
}

.list {
  display: flex;
  height: adjust.adjustPx(344px);
}

.item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: adjust.adjustPx(32px);
  height: adjust.adjustPx(280px);
  outline: none;
  background-color: var(--color-bg-modal);

  img {
    height: 100%;
    object-fit: cover;
  }

  .actor {
    color: var(--color-text-secondary);
    text-align: center;

    @include smartTvFonts.SmartTvTitle-3();
  }

  &:last-child {
    margin-right: 0;
  }
}

.moment {
  width: adjust.adjustPx(480px);
  min-width: adjust.adjustPx(480px);
  height: adjust.adjustPx(280px);
  min-height: adjust.adjustPx(280px);
  border-radius: var(--g-border-round-24);

  img {
    transform: scale(1.35, 1.35);
    height: 100%;
    object-fit: cover;
  }

  .subtitle {
    position: absolute;
    top: adjust.adjustPx(280px);
    left: adjust.adjustPx(8px);

    display: -webkit-box;
    min-width: adjust.adjustPx(480px);

    overflow: hidden;
    color: var(--color-notheme-text-primary);
    text-align: start;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;

    @include smartTvFonts.SmartTvBody-1();
  }
}
.playIcon {
  position: absolute;
  left: 50%;
  right: 0;
  z-index: map-get($map: $layers, $key: --z-index-heading);
  transform: translateX(-50%);
  width: adjust.adjustPx(96px);
  height: adjust.adjustPx(96px);
}

.poster {
  width: adjust.adjustPx(376px);
  min-width: adjust.adjustPx(376px);
  height: adjust.adjustPx(508px);
  min-height: adjust.adjustPx(508px);
  border-radius: adjust.adjustPx(24px);

  img {
    height: 100%;
    object-fit: cover;
  }

  .subtitle {
    position: absolute;
    top: adjust.adjustPx(516px);
    left: adjust.adjustPx(8px);

    display: -webkit-box;
    min-width: adjust.adjustPx(376px);

    overflow: hidden;
    color: var(--color-notheme-text-primary);
    text-align: start;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    @include smartTvFonts.SmartTvBody-1();
  }
}

.person {
  width: adjust.adjustPx(270px);
  min-width: adjust.adjustPx(270px);
  height: adjust.adjustPx(270px);
  min-height: adjust.adjustPx(270px);
  border-radius: adjust.adjustPx(24px);

  .subtitle {
    position: absolute;
    top: adjust.adjustPx(270px);
    left: adjust.adjustPx(8px);
    text-align: start;

    @include smartTvFonts.SmartTvBody-1();

    .role {
      color: var(--color-bg-tertiary);

      @include smartTvFonts.SmartTvBody-2();
    }
  }
}

.series {
  width: adjust.adjustPx(480px);
  height: adjust.adjustPx(280px);
  border-radius: adjust.adjustPx(24px);
}

.link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: adjust.adjustPx(24px);
  outline: none;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: adjust.adjustPx(24px);
    content: '';
  }

  &:hover::after,
  &.active::after {
    box-shadow: inset 0 0 0 adjust.adjustPx(7px) var(--color-bg-accent);
  }
}
</style>
