import type { AppEventType } from '@package/sdk/src/core';
import { Disposable, DisposableStore, EventEmitter } from '@package/sdk/src/core';
import AppEvent from '@package/sdk/src/core/event/event';

type MediaAnalyzerErrorType = 'playback-failed' | 'playback-interrupted';

export class MediaAnalyzerEvent<T extends MediaAnalyzerErrorType> extends AppEvent {
  constructor(public readonly errorType: T) {
    super();
  }

  public get type(): AppEventType {
    return 'default';
  }
}

export interface MediaAnalyzerEventMap {
  'playback-failed': MediaAnalyzerEvent<'playback-failed'>;
  'playback-interrupted': MediaAnalyzerEvent<'playback-interrupted'>;
}

export class AbstractMediaAnalyzer extends Disposable {
  public readonly emitter = new EventEmitter<MediaAnalyzerEventMap>();

  protected readonly disposableStore = new DisposableStore();

  constructor() {
    super();
  }
}
