<template>
  <UIButton
    :variation="variation"
    :disabled="disabled"
    role="tab"
    :class="{
      [$style.tab]: true,
      [$style.disabled]: true,
      [$style.disabledSelected]: selected,
      [$style.tabSecondary]: variant === 'secondary',
      [$style.tabSecondarySelected]: variant === 'secondary' && selected,
    }"
  >
    <template #icon><slot name="icon" /></template>
    <slot />
  </UIButton>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import UIButton from '../button/UIButton.vue';

export interface Props {
  selected?: boolean;
  variant?: 'primary' | 'secondary';
  isActive?: boolean;
  disabled?: boolean;
}

const { selected = false, isActive = false, variant = 'primary' } = defineProps<Props>();

const variation = computed(() => {
  if (isActive) {
    return 'primary';
  }

  if (variant === 'primary') {
    return selected ? 'primary' : 'secondary';
  }

  return 'primary';
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 adjust.adjustPx(25px) !important;
  width: auto !important;
  min-height: adjust.adjustPx(59px) !important;
  border: none !important;
  border-radius: adjust.adjustPx(16px) !important;
  cursor: pointer;

  @include smartTvFonts.SmartTvLabel-2();

  &:hover,
  &:active {
    background-color: var(--color-bg-accent) !important;
  }

  &Secondary {
    height: adjust.adjustPx(80px);
    min-height: adjust.adjustPx(80px);
    border-radius: adjust.adjustPx(16px);
  }

  &Secondary:hover,
  &Secondary:active {
    background-color: var(--color-bg-ghost);
    color: var(--color-text-primary);
    box-shadow: inset 0 0 0 adjust.adjustPx(4px) var(--color-bg-accent);
  }

  &SecondarySelected {
    background-color: var(--color-bg-ghost);
    color: var(--color-bg-accent);
    box-shadow: inset 0 0 0 adjust.adjustPx(4px) var(--color-bg-accent);
  }
}

.disabled {
  &:disabled {
    background-color: transparent;
    opacity: 1;
    color: var(--color-text-primary);
    cursor: not-allowed;
  }
}

.disabledSelected {
  &:disabled {
    background-color: var(--color-bg-ghost);
    opacity: 1;
    color: var(--color-text-primary);
    cursor: not-allowed;
  }
}
</style>
