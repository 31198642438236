<template></template>

<script lang="ts" setup>
import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { DisposableStore, isDefined, toDisposable } from '@package/sdk/src/core';
import { isTouchDevice } from '@PLAYER/player/base/dom';
import defineGlobalVueProperty from '@PLAYER/player/modules/global/define-global-vue-property';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import onInitialPlaybackStarted from '@PLAYER/player/modules/hooks/on-initial-playback-started';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import { PlayerGlobalProperty } from '@PLAYER/player/modules/instance/interfaces';
import useMouseHandler from '@PLAYER/player/modules/mouse/use-mouse-handler';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import { watchOnce } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, getCurrentInstance, nextTick, watch, watchEffect } from 'vue';

const props = defineProps<{
  requestVideoControls: VoidFunction;
  releaseVideoControls: VoidFunction;
  requestMyChannelVideoStateControls: VoidFunction;
  releaseMyChannelVideoStateControls: VoidFunction;
}>();

const { isSmartTV, isWeb } = usePlatform();
const { isFocused, isLinkedKinomShown } = storeToRefs(useLayoutStore());
const { isControlsVisible, isMyChannelVideoStateControlsVisible } = storeToRefs(useVideoControlsStore());
const { isInitialized } = storeToRefs(useVideoUIStore());
const disposables = new DisposableStore();
const { isMyChannelPlayer } = useProjector();

const mouse = useMouseHandler();
const app = getCurrentInstance();

const isWebTouchDevice = isWeb && isTouchDevice;

const releaseVideoControlDelay = computed(() => {
  if (isLinkedKinomShown.value) {
    return ConstantsConfigPlayer.getProperty('linkedKinomShowTimeoutMs');
  }

  if (isSmartTV) {
    return ConstantsConfigPlayer.getProperty('controlsVisibleSmartTvTimeoutMs');
  }

  return ConstantsConfigPlayer.getProperty('controlsVisibleWebDefaultTimeoutMs');
});

let timeoutId: number | undefined;

const updateActiveState = () => {
  if (isDefined(timeoutId)) {
    window.clearTimeout(timeoutId);
  } else {
    Reflect.apply(props.requestVideoControls, undefined, []);
    Reflect.apply(props.requestMyChannelVideoStateControls, undefined, []);
  }

  timeoutId = window.setTimeout(() => {
    Reflect.apply(props.releaseVideoControls, undefined, []);
    Reflect.apply(props.releaseMyChannelVideoStateControls, undefined, []);

    timeoutId = undefined;
  }, releaseVideoControlDelay.value);
};

defineGlobalVueProperty(app, PlayerGlobalProperty.Touch, updateActiveState);

watch(
  () => isInitialized.value,
  () => {
    if (isSmartTV) {
      updateActiveState();
    }
  },
);

const onKeydown = () => {
  if (isWeb && !isFocused.value) {
    return;
  }

  updateActiveState();
};

const onWheel = () => () => {
  if (isFocused.value) {
    return;
  }

  updateActiveState();
};

watchOnce(isLinkedKinomShown, (val) => {
  if (val) {
    updateActiveState();
  }
});

watchEffect(() => {
  let shouldUpdateActiveState: boolean = false;

  if (isWebTouchDevice) {
    shouldUpdateActiveState = isMyChannelPlayer.value
      ? isMyChannelVideoStateControlsVisible.value
      : isControlsVisible.value;
  }

  if (shouldUpdateActiveState) {
    updateActiveState();
  }
});

onInitialPlaybackStarted(updateActiveState);

useEffect(() => {
  window.addEventListener('wheel', onWheel);
  disposables.add(toDisposable(() => window.removeEventListener('wheel', onWheel)));

  window.addEventListener('keydown', onKeydown);
  disposables.add(toDisposable(() => window.removeEventListener('keydown', onKeydown)));

  if (isTouchDevice) {
    disposables.add(mouse.on('touchend', updateActiveState));
  }

  nextTick(updateActiveState);

  disposables.add(mouse.on('mousedown', updateActiveState));
  disposables.add(mouse.on('mousemove', updateActiveState));
  disposables.add(mouse.on('mouseover', updateActiveState));

  return () => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    disposables.dispose();
  };
});
</script>
