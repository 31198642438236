<template>
  <div ref="el" :class="$style.wrapper">
    <article :class="$style.settings">
      <SettingsPageNavigation @logout="onLogout" />

      <section :class="$style.content">
        <router-view />
      </section>
    </article>

    <FullScreenModal v-if="logoutModalShown">
      <AppExitModal
        translation-page="modals.logout"
        @close="logoutModalResolve(false)"
        @exit="logoutModalResolve(true)"
      />
    </FullScreenModal>
  </div>
</template>

<script setup lang="ts">
import { useAccountPageAnalytics } from '@package/sdk/src/analytics';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { analyticService, authService, FocusKeys, RouterPage, routerService } from '@SMART/index';
import { provide, ref } from 'vue';

import AppExitModal from '@/components/modal/AppExitModal.vue';
import FullScreenModal from '@/components/modal/FullScreenModal.vue';

import SettingsPageNavigation from './components/SettingsPageNavigation.vue';

const accountPageAnalytics = useAccountPageAnalytics(analyticService.sender);

const { el, focusKey } = useNavigatable({
  focusKey: FocusKeys.SETTINGS_PAGE,
});
provide('parentFocusKey', focusKey.value);

const logoutModalShown = ref(false);
const logoutModalResolve = ref<Function>(() => {});

const showAppExitModal = async () => {
  logoutModalShown.value = true;

  return new Promise((res) => {
    logoutModalResolve.value = (value: boolean) => {
      logoutModalShown.value = false;

      value ? accountPageAnalytics.onClickExitConfirm() : accountPageAnalytics.onClickExitCancel();

      res(value);
    };
  });
};

const onLogout = async () => {
  accountPageAnalytics.onClickExit();

  const res = await showAppExitModal();
  if (!res) {
    return;
  }

  await authService.signOut();

  return routerService.push({ name: RouterPage.MainPage });
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0 0 adjust.adjustPx(138px);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.settings {
  display: flex;
  height: 100%;
}

.content {
  display: flex;
  flex-flow: column;
  padding: adjust.adjustPx(80px);
  width: 100%;
}
</style>
