import { Disposable, EventEmitter, type IDisposable } from '@package/sdk/src/core';

interface MouseHandlerMap {
  click: MouseEvent;
  dblclick: MouseEvent;
  touchstart: TouchEvent;
  touchend: TouchEvent;
  mousedown: TouchEvent;
  mousemove: TouchEvent;
  mouseover: MouseEvent;
  mouseleave: MouseEvent;
}

export class MouseHandler extends Disposable {
  readonly #emitter = new EventEmitter<MouseHandlerMap>();

  constructor() {
    super();
  }

  public emit(eventName: keyof MouseHandlerMap, event: MouseEvent | TouchEvent) {
    this.#emitter.emit(eventName, event);
  }

  public on<T extends keyof MouseHandlerMap>(event: T, callback: (arg: MouseHandlerMap[T]) => void): IDisposable {
    return this.#emitter.on(event, callback);
  }

  public dispose() {
    this.#emitter.dispose();
  }
}
