<template>
  <section :class="$style.qr">
    <div :class="$style.qrVertical">
      <div :class="$style.qrContainer">
        <UITypography :class="$style.header" shimmer-variant="title" :is-loading="isLoading">
          {{ $t('pages.auth.qrLogin.qrTitle') }}
        </UITypography>
        <UITypography :class="$style.hintBlock" shimmer-variant="body" :is-loading="isLoading" :lines="2">
          {{ $t('pages.auth.qrLogin.qrHint') }}
        </UITypography>
        <QrCode :class="$style.qrCanvas" :url="webURL" :is-loading="isLoading" @click="onQRCodeClick()" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import { AnalyticAuthType, useAuthPageAnalytics } from '@package/sdk/src/analytics';
import {
  analyticService,
  authService,
  deviceService,
  environmentService,
  onboardingService,
  OperationSystem,
  useAuthActions,
  WebAppRoute,
  WebRouteQuery,
} from '@package/smarttv-base/src';
import { onBeforeUnmount, onMounted, ref } from 'vue';

import QrCode from '@/components/qr-code/QrCode.vue';
import UITypography from '@/components/typography/UITypography.vue';

defineProps<{
  code: string;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:code', code: string): void;
}>();

const { redirectWithHistoryClear } = useAuthActions();
const authPageAnalytics = useAuthPageAnalytics(analyticService.sender);

const webAppURL = environmentService.getVariable('webAppURL');

const webURL = ref('');

let checkTvTokenInterval: number;

const generateQr = async () => {
  const { tvCode, tvToken } = await authService.createTvToken();

  emit('update:code', tvCode);

  const url = webAppURL + WebAppRoute.SmartTvCode + '?' + WebRouteQuery.SmartTvCode + '=' + tvCode;
  webURL.value = url;

  const onTokenUpdated = async () => {
    try {
      await authService.checkTvToken(tvToken);

      authPageAnalytics.onSuccessAuthorization({ authMethod: 'login', auth: AnalyticAuthType.Authorization });
      onboardingService.resetParentalCode();

      await redirectWithHistoryClear();
    } catch (error) {
      // it's ok
    }
  };

  checkTvTokenInterval = window.setInterval(
    onTokenUpdated,
    ConstantsConfigInstanceSmartTV.getProperty('authCheckTvTokenTimeoutMs'),
  );
};

const onQRCodeClick = () => {
  if (deviceService.os !== OperationSystem.Desktop) {
    return;
  }

  const browserWindow = window.open(webURL.value, '_blank');

  window.setTimeout(() => browserWindow?.close(), 10000);
};

onMounted(generateQr);

onBeforeUnmount(() => {
  if (checkTvTokenInterval) {
    window.clearInterval(checkTvTokenInterval);
  }
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.qr {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: adjust.adjustPx(80px);
  width: 52%;
  height: fit-content;
  max-height: adjust.adjustPx(732px);
  border-right: adjust.adjustPx(2px) solid var(--color-stroke-div-primary);
}

.qrVertical {
  position: relative;
  display: flex;
  margin-bottom: adjust.adjustPx(120px);
}

.qrContainer {
  display: flex;
  flex-direction: column;
}

.qrCanvas {
  width: adjust.adjustPx(432px) !important;
  height: adjust.adjustPx(432px) !important;

  border-radius: adjust.adjustPx(24px);
}

.header {
  @include smartTvFonts.SmartTvTitle-3();

  margin-bottom: adjust.adjustPx(16px);
  min-height: adjust.adjustPx(64px);
}

.hintBlock {
  margin-bottom: adjust.adjustPx(48px);
  max-width: adjust.adjustPx(700px);
  line-height: adjust.adjustPx(52px);
  color: var(--color-text-tertiary);
}
</style>
