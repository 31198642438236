import type MediaSourceTech from '@package/media-player-tech/src/media-source-tech';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import type { Ref } from 'vue';
import { inject } from 'vue';

const injectKey = 'getMediaSourceTech';

export default function useSafeGetMediaSourceTech() {
  const { getDefault } = useDefaultProvide();

  const getMediaSourceTech = inject<Ref<MediaSourceTech>>(injectKey, getDefault(injectKey));

  if (!getMediaSourceTech) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return getMediaSourceTech;
}
