import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import { type Ref } from 'vue';

const DOUBLE_TAP_TIME = ConstantsConfigPlayer.getProperty('doubleTapPlaybackTimeoutMs');

type UseDoubleTapOptions = (event: TouchEvent) => void;

export default function useDoubleTap(element: Ref<HTMLElement | undefined>, callback: UseDoubleTapOptions) {
  let lastTapTimestamp = 0;
  let timeoutId: number;

  const onTouchEnd = (event: TouchEvent) => {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTapTimestamp;

    window.clearTimeout(timeoutId);

    if (tapLength < DOUBLE_TAP_TIME && tapLength > 0) {
      event.preventDefault();
      callback(event);
    } else {
      timeoutId = window.setTimeout(() => {
        window.clearTimeout(timeoutId);
      }, DOUBLE_TAP_TIME);
    }

    lastTapTimestamp = currentTime;
  };

  useEffect(() => {
    if (!element.value) {
      throw new UnexpectedComponentStateError('doubleTapEl');
    }

    element.value.addEventListener('touchend', onTouchEnd);

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }

      element.value?.removeEventListener('touchend', onTouchEnd);
    };
  });
}
