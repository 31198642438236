<template>
  <div :class="{ [$style.playbackTimeView]: true, [$style.playbackTimeViewWeb]: isWeb }">
    <span v-if="isCurrentTimeShown" :class="currentTimeCssClassName">
      {{ formatTime(safeCurrentTime) }}
    </span>
    <span v-if="isCurrentTimeShown && isDurationShown" :class="currentTimeCssClassName">&nbsp;/&nbsp;</span>
    <span v-if="isDurationShown" :class="durationCssClassName">
      {{ formatTime(duration) }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { isDefined } from '@package/sdk/src/core';
import { formatTime } from '@PLAYER/player/base/date';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import { computed } from 'vue';

const props = defineProps<{
  currentTimeCssClassName?: string;
  durationCssClassName?: string;

  isCurrentTimeShown: boolean;
  isDurationShown: boolean;
  currentTime: number;
  duration: number;
}>();

const { isWeb } = usePlatform();

const safeCurrentTime = computed(() => (isDefined(props.currentTime) ? props.currentTime : 0));
</script>

<style lang="scss" module>
.playbackTimeView {
  width: var(--vod-playback-time-view-width);
  color: var(--color-notheme-text-primary);
}

.playbackTimeViewWeb {
  padding-bottom: var(--g-spacing-4);
}
</style>
