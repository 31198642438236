<template>
  <Transition>
    <UINotification
      v-if="!isOnboardingShown"
      :class="$style.notification"
      :title="$t('pages.moments.onboarding.firstTimeTitle')"
      :subtitle="$t('pages.moments.onboarding.firstTimeSubTitle')"
    />
  </Transition>

  <Transition>
    <UINotification
      v-if="!isOnboardingLikeShown"
      :class="$style.notification"
      :title="$t('pages.moments.onboarding.likeTitle')"
      :subtitle="$t('pages.moments.onboarding.likeSubtitle')"
    />
  </Transition>
  <Transition>
    <UINotification
      v-if="!isOnboardingDislikeShown"
      :class="$style.notification"
      :title="$t('pages.moments.onboarding.dislikeTitle')"
      :subtitle="$t('pages.moments.onboarding.dislikeSubtitle')"
    />
  </Transition>
  <Transition>
    <UINotification
      v-if="!isOnboardingSaveShown"
      :class="$style.notification"
      :title="$t('pages.moments.onboarding.saveTitle')"
      :subtitle="$t('pages.moments.onboarding.saveSubtitle')"
    />
  </Transition>
  <Transition>
    <UINotification
      v-if="isAuthError"
      :class="$style.notification"
      :title="$t('pages.moments.limitReached.title')"
      :subtitle="$t('pages.moments.limitReached.subtitle')"
    >
      <template #actions>
        <div :ref="updateItemRef" :class="$style.actions">
          <NavigatableItem :class="$style.button" :active-class="$style.active" :tag="UIButton" @click="$emit('login')">
            {{ $t('pages.moments.limitReached.login') }}
          </NavigatableItem>
          <NavigatableItem
            :class="$style.button"
            :active-class="$style.active"
            :tag="UIButton"
            @click="$emit('cancel')"
          >
            {{ $t('pages.moments.limitReached.later') }}
          </NavigatableItem>
        </div>
      </template>
    </UINotification>
  </Transition>
</template>

<script setup lang="ts">
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { FocusKeys } from '@SMART/index';
import { nextTick, onMounted, provide, watch } from 'vue';

import UIButton from '@/components/button/UIButton.vue';
import UINotification from '@/components/notification/UINotification.vue';

interface Props {
  isOnboardingShown?: boolean;
  isOnboardingLikeShown?: boolean;
  isOnboardingDislikeShown?: boolean;
  isOnboardingSaveShown?: boolean;
  isAuthError?: boolean;
}

const props = defineProps<Props>();

defineEmits(['login', 'cancel']);

const { el, focusKey, addFocusable, removeFocusable } = useNavigatable({
  focusKey: FocusKeys.MY_CHANNEL_NOTIFICATIONS_ACTIONS,
  hasGlobalAccess: true,
});
provide('parentFocusKey', focusKey.value);

const updateItemRef = (element?: HTMLElement) => {
  if (element) {
    el.value = element;
  }
};

watch(
  () => props.isAuthError,
  async (value) => {
    if (value) {
      await nextTick();
      addFocusable();
    } else {
      removeFocusable();
    }
  },
);

onMounted(() => {
  removeFocusable();
});
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/mixins';
@import '@/styles/colors';

.actions {
  display: flex;
  width: 100%;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: adjustPx(24px);
    margin-right: adjustPx(24px);
    padding: adjustPx(16px) adjustPx(24px);
    width: adjustPx(144px);
    height: adjustPx(80px);
    border-radius: adjustPx(20px);
    background: var(--color-bg-button);

    @include f-label-2;
  }

  .active {
    background: var(--color-notheme-bg-accent);
    color: var(--color-notheme-text-accent);
  }
}

.notification {
  position: fixed;
  bottom: adjustPx(176px);
  right: adjustPx(48px);
}
</style>
