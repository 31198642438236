import useLogger from '@package/logger/src/use-logger';
import { Disposable, EventEmitter, type IDisposable } from '@package/sdk/src/core';

type EventFunction = (event: Event) => void;

interface HTMLVideoElementEventMapPlayer extends HTMLVideoElementEventMap {
  webkitplaybacktargetavailabilitychanged: Event;
}

const logger = useLogger('video-event-handler', 'media-player');
logger.level = -999;

export default class VideoEventHandler extends Disposable {
  readonly #emitter: EventEmitter<HTMLVideoElementEventMapPlayer> = new EventEmitter<HTMLVideoElementEventMapPlayer>();
  readonly #handlers: Partial<Record<keyof HTMLVideoElementEventMapPlayer, EventFunction>>;

  #videoEl: HTMLVideoElement;
  #initialized = false;

  constructor() {
    super();

    this.#handlers = {
      ratechange: (event: Event) => {
        this.doEmit('ratechange', event);
      },
      playing: (event: Event) => {
        this.doEmit('playing', event);
      },
      durationchange: (event: Event) => {
        this.doEmit('durationchange', event);
      },
      volumechange: (event: Event) => {
        this.doEmit('volumechange', event);
      },
      timeupdate: (event: Event) => {
        this.doEmit('timeupdate', event);
      },
      webkitplaybacktargetavailabilitychanged: (event: Event) => {
        this.doEmit('webkitplaybacktargetavailabilitychanged', event);
      },
      ended: (event: Event) => {
        this.doEmit('ended', event);
      },
      seeking: (event: Event) => {
        this.doEmit('seeking', event);
      },
      seeked: (event: Event) => {
        this.doEmit('seeked', event);
      },
      pause: (event: Event) => {
        this.doEmit('pause', event);
      },
      play: (event: Event) => {
        this.doEmit('play', event);
      },
      loadeddata: (event: Event) => {
        this.doEmit('loadeddata', event);
      },
      loadedmetadata: (event: Event) => {
        this.doEmit('loadedmetadata', event);
      },
    };
  }

  private doEmit(event: keyof HTMLVideoElementEventMapPlayer, domEvent: Event) {
    this.#emitter.emit(event, domEvent);
  }

  public registerListeners(videoEl: HTMLVideoElement): void {
    this.#videoEl = videoEl;
    this.#initialized = true;

    const entries = Object.entries(this.#handlers);
    for (const [key, handler] of entries) {
      this.#videoEl.addEventListener(key, handler);
    }
  }

  public addOnceEventListener(event: keyof HTMLVideoElementEventMapPlayer, listener: VoidFunction): void {
    return this.#emitter.once(event, listener);
  }

  public addEventListener(event: keyof HTMLVideoElementEventMapPlayer, listener: EventFunction): IDisposable {
    return this.#emitter.on(event, listener as any);
  }

  public removeEventListener(event: keyof HTMLVideoElementEventMapPlayer, listener: EventFunction): void {
    this.#emitter.removeEventListener(event, listener);
  }

  public trigger(event: keyof HTMLVideoElementEventMapPlayer): void {
    if (!['timeupdate'].includes(event)) {
      logger.debug(`VideoEvent - ${event as string}`, {
        fakeEvent: true,
      });
    }

    this.#emitter.emit(event);
  }

  public dispose() {
    const entries = Object.entries(this.#handlers);
    this.#initialized = false;

    for (const [key, handler] of entries) {
      this.#videoEl.removeEventListener(key, handler);
    }
  }
}
