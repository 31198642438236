// @ts-nocheck

import type { PlayerInstanceConstructorOptions } from '@PLAYER/player/modules/instance/interfaces';
import { type CustomComponentMap, VijuPlayer } from '@PLAYER/player/modules/instance/player';
import { type App } from 'vue';

import Root from './components/Root.vue';

class SmartTvVijuPlayer extends VijuPlayer {
  constructor(options: PlayerInstanceConstructorOptions, components?: CustomComponentMap) {
    super(options, Root as unknown as App<Element>, components);
  }

  protected registerComponents(app: App<Element>) {
    //
  }
}

export { SmartTvVijuPlayer };
