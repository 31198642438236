<template>
  <div ref="el" :class="$style.tooltip" role="dialog">
    <smart-tv-video-control-button
      v-for="(option, index) in options"
      :key="option.name"
      :autofocus="index === options.length - 1"
      :class="{ [$style.button]: true, [$style.selected]: option.name === selectedOption?.name }"
      :is-label-shown="true"
      :text="option.name"
      :on-click="() => onSelectOption(option)"
    >
      <div :class="$style.optionText">{{ option.name }}</div>
    </smart-tv-video-control-button>
  </div>
</template>

<script setup lang="ts">
import { type OptionItem } from '@PLAYER/player/modules/video/use-player-settings';
import SmartTvVideoControlButton from '@PLAYER/player/versions/smart/components/controls/SmartTvVideoControlButton.vue';
import useSmartNavigatable from '@PLAYER/player/versions/smart/modules/smart-navigation/use-smarttv-navigatable';
import { provide } from 'vue';

defineProps<{
  onSelectOption: (option: OptionItem) => void;
  selectedOption?: OptionItem;
  options: OptionItem[];
}>();

const { useNavigatable } = useSmartNavigatable();

const { el, hasFocusedChild, focusKey } = useNavigatable({
  trackChildren: true,
  focusKey: 'SMART_TV_TOOLTIP',
});
provide('parentFocusKey', focusKey.value);

defineExpose({
  hasFocusedChild,
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.tooltip {
  position: absolute;
  bottom: adjust.adjustPx(96px);
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  background-color: transparent;
}

.button {
  display: flex;
  justify-content: center;
  margin-bottom: adjust.adjustPx(8px);

  padding: adjust.adjustPx(20px);
  outline: 0;
  @include smartTvFonts.SmartTvBody-3();
}

.selected {
  background-color: var(--color-notheme-bg-hover-70);
}
</style>
