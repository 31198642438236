import type { KeyboardHandler } from '@package/sdk/src/core';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import { inject } from 'vue';

const injectKey = 'handler.keyboard';

export default function useKeyboardHandler() {
  const { getDefault } = useDefaultProvide();

  const keyboard = inject<KeyboardHandler>(injectKey, getDefault(injectKey));

  if (!keyboard) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return keyboard;
}
