<template>
  <div
    :class="{
      [$style.bar]: true,
      [$style.backgroundBar]: showBackgroundProgress,
    }"
    :style="{ width: $toPixel(intervalWidth) }"
  >
    <div
      v-if="showBackgroundProgress"
      :class="$style.backgroundBarProgress"
      :style="{ width: $toPercent(backgroundWidth) }"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { type MediaChapter } from '@PLAYER/player/modules/interfaces/chapters';
import { secondToPixel } from '@PLAYER/player/modules/timeline/coordinates';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    startOffset: number;
    endOffset: number;
    pixelFactor: number;
    currentTime: number;
    showBackgroundProgress: boolean;
    chapters: MediaChapter[];
    chapterIndex: number;
  }>(),
  {
    showBackgroundProgress: false,
  },
);

const rightMarginInPx = 4;

const startOffsetPx = computed(() => secondToPixel(props.startOffset, props.pixelFactor));
const endOffsetPx = computed(() => secondToPixel(props.endOffset, props.pixelFactor));

const isLastChapter = computed(() => props.chapterIndex + 1 === props.chapters.length);

const intervalWidth = computed(() => {
  if (isLastChapter.value) {
    return endOffsetPx.value - startOffsetPx.value - rightMarginInPx * props.chapterIndex;
  }

  return endOffsetPx.value - startOffsetPx.value;
});

const backgroundWidth = computed(() => {
  const currentTime = props.currentTime;

  if (props.startOffset > currentTime) {
    return 0;
  }

  if (props.endOffset < currentTime) {
    return 100;
  }

  const percentValue = (props.endOffset - props.startOffset) / 100;
  const interval = currentTime - props.startOffset;

  return interval / percentValue;
});

const isActive = computed(() => {
  const currentTime = props.currentTime;

  if (currentTime === 0) {
    return false;
  }

  return currentTime > props.startOffset;
});
</script>

<style lang="scss" module>
.bar {
  position: relative;
  margin-right: var(--g-spacing-4);
  padding: 0;
  height: 4px;

  &:last-of-type {
    margin: 0;
  }

  transition: all 0.2s ease;

  &:hover {
    transform: scaleY(1.5);
    background-color: var(--color-notheme-text-primary);
  }
}

.backgroundBar {
  background-color: var(--color-text-secondary);
}

.backgroundBarProgress {
  position: absolute;
  height: 100%;
  background-color: var(--color-notheme-bg-accent);
}

.active {
  &:hover {
    background-color: var(--color-notheme-bg-accent);
  }
}

.empty {
  transform: initial;
  background-color: var(--color-text-secondary);
}
</style>
