import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { Disposable, isUndefinedOrNull, timeout, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { isChromium, loadScriptElement } from '@PLAYER/player/base/dom';
import { type AnyFunction } from '@PLAYER/player/base/function';
import {
  ChromecastInstance,
  type ChromecastMetadataOptions,
} from '@PLAYER/player/modules/chromecast/chromecast-instance';

class ChromecastManager extends Disposable {
  public chromecast: ChromecastInstance;
  private onScriptLoadedFunc: AnyFunction;

  constructor() {
    super();
  }

  public init() {
    if (!isChromium) {
      return;
    }

    const chromecastGoogleScriptElement = document.head.querySelector('[data-type="chromecast"]');
    const isScriptLoaded = !isUndefinedOrNull(chromecastGoogleScriptElement);

    if (isScriptLoaded) {
      return this.onLoadChromecastScript();
    }

    /**
     * @description
     * Для работы chromecast, нужно подругизть скрипт с CDN google.
     */
    const chromecastGoogleScriptSrc = ConstantsConfigPlayer.getProperty('chromeCastScriptSrc');

    if (!isScriptLoaded) {
      loadScriptElement(chromecastGoogleScriptSrc, {
        onLoad: this.onLoadChromecastScript,
        async: false,
        defer: false,
        attrs: {
          'data-type': 'chromecast',
        },
      });
    }
  }

  private onLoadChromecastScript = async () => {
    await timeout(ConstantsConfigPlayer.getProperty('chromecastLoadScriptTimeoutMs'));

    this.chromecast = new ChromecastInstance();

    if (this.onScriptLoadedFunc) {
      Reflect.apply(this.onScriptLoadedFunc, undefined, []);
    }
  };

  public setOnLoadedCallback(func: AnyFunction) {
    this.onScriptLoadedFunc = func;
  }

  public requestChromecastSession = (src: string, metadata?: ChromecastMetadataOptions) => {
    if (!this.chromecast.available) {
      throw new UnexpectedComponentStateError('chromecast.available');
    }

    this.chromecast.cast(src, metadata);
  };

  public stopChromecastSession() {
    this.chromecast.disconnect();
  }
}

const ChromecastInstanceManager = new ChromecastManager();

export default ChromecastInstanceManager;
