<template>
  <section ref="el" :class="$style.metadataSection">
    <h2 :class="$style.metadataTitle">{{ title }}</h2>
    <div :class="$style.metadataTags">
      <NavigatableItem
        v-for="(tag, index) in tags"
        :key="tag.slug"
        :class="$style.metadataTag"
        :active-class="$style.active"
        :tag="AppButton"
        :text="tag.title"
        @active="(element) => emit('activated', element?.offsetTop || 0, index + startIndex)"
        @click="emit('selected', { [filterType]: tag.slug })"
      >
        {{ tag }}
      </NavigatableItem>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { Country, Genre } from '@package/sdk/src/api';
import { FocusKeys } from '@package/smarttv-base/src';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { provide } from 'vue';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

interface Props {
  title: string;
  filterType: 'genre' | 'country';
  tags: Country[] | Genre[];
  startIndex: number;
}

const props = defineProps<Props>();

const { el, focusKey } = useNavigatable({
  focusKey: FocusKeys.MEDIA_CARD_METADATA_SECTION(props.filterType.toUpperCase()),
  hasGlobalAccess: true,
  saveLastFocusedChild: true,
  // TODO убрать после фикса BackButton
  focusBoundaryDirections: ['left'],
  isFocusBoundary: true,
});
provide('parentFocusKey', focusKey.value);

const emit = defineEmits<{
  (event: 'activated', offset: number, index: number): void;
  (event: 'selected', options: { genre?: string; country?: string }): void;
}>();
</script>

<style module lang="scss">
@import '@package/ui/src/styles/smarttv-fonts';
@import '@/styles/mixins';

.metadataSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: adjustPx(504px);
}

.metadataTitle {
  @include SmartTvSubtitle-1();
}

.metadataTags {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: adjustPx(24px);
  width: 100%;
  font-size: adjustPx(32px);
  line-height: 1;
  text-align: center;
}

.metadataTag {
  align-self: stretch;
  margin-bottom: adjustPx(24px);
  margin-right: adjustPx(24px);
  padding: adjustPx(24px);
  border: none;
  border-radius: adjustPx(16px);
  background-color: rgba(63, 80, 86, 1);
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
}
</style>
