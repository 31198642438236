import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import type { MouseHandler } from '@PLAYER/player/modules/mouse/mouse-handler';
import { inject } from 'vue';

const injectKey = 'handler.mouse';

export default function useMouseHandler() {
  const { getDefault } = useDefaultProvide();

  const mouse = inject<MouseHandler>(injectKey, getDefault(injectKey));

  if (!mouse) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return mouse;
}
