import { toPercent } from '@package/sdk/src/core';
import { isMobile } from '@PLAYER/player/base/dom';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useSafeVideoElement from '@PLAYER/player/modules/video/use-safe-video-element';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

export default function useVolumeActions() {
  const videoInteractions = useVideoInteractions();
  const videoControlsStore = useVideoControlsStore();
  const videoUIStore = useVideoUIStore();
  const { volume } = storeToRefs(videoUIStore);
  const videoEl = useSafeVideoElement();

  const isVideoMuted = ref(true);

  const volumePercentage = computed(() => Math.round(volume.value * 100));

  const checkIsVideoMutedAndUnmute = () => {
    if (videoEl.muted) {
      videoEl.muted = false;
    }
  };

  const volumeMode = computed<'off' | 'half' | 'full'>(() => {
    if (isMobile) {
      return isVideoMuted.value ? 'off' : 'full';
    }

    return volume.value === 0 ? 'off' : volume.value < 0.5 ? 'half' : 'full';
  });

  const lastKnownVolume = ref<number>(1);

  watch(
    volume,
    (val) => {
      if (isMobile) {
        isVideoMuted.value = Boolean(!val);
      }

      if (!val) {
        return;
      }

      lastKnownVolume.value = val;
    },
    { immediate: true },
  );

  const onSwitchVolume = () => {
    if (volumeMode.value === 'off') {
      checkIsVideoMutedAndUnmute();

      if (isMobile) {
        isVideoMuted.value = false;
        videoEl.muted = false;
      }

      return videoInteractions.setVolume(lastKnownVolume.value);
    }

    if (isMobile) {
      isVideoMuted.value = true;
      videoEl.muted = true;
    }

    videoInteractions.setVolume(0);
  };

  interface SafeSetVolumeOptions {
    showCurrentAction?: boolean;
  }

  const safeSetVolume = (value: number, options: SafeSetVolumeOptions = { showCurrentAction: false }) => {
    let normalizedValue: number;

    if (value <= 0) {
      normalizedValue = 0;
      if (isMobile) {
        isVideoMuted.value = true;
        videoEl.muted = true;
      }
    } else if (value >= 1) {
      checkIsVideoMutedAndUnmute();
      normalizedValue = 1;
    } else {
      checkIsVideoMutedAndUnmute();
      normalizedValue = Number(value || 0);
    }

    videoInteractions.setVolume(normalizedValue);
    videoUIStore.setVolume(normalizedValue);

    if (options.showCurrentAction) {
      videoControlsStore.setCurrentAction({
        type: normalizedValue <= 0.05 ? 'volume-off' : 'volume-on',
        text: toPercent(volumePercentage.value),
      });
    }
  };

  return {
    volumePercentage,
    volumeMode,
    safeSetVolume,
    onSwitchVolume,
  };
}
