import type { Episode, Season } from '@package/sdk/src/api';
import { indexOutOfRange } from '@package/sdk/src/core';
import useState from '@PLAYER/player/modules/global/use-state';
import { defineStore } from 'pinia';
import { computed } from 'vue';

const PLAYLIST_STORE_NAME = 'playlist';

const usePlaylistStore = defineStore(PLAYLIST_STORE_NAME, () => {
  const [playlists, setPlaylists] = useState<Season[]>([]);

  const [currentPlaylistItemId, setCurrentPlaylistItemId] = useState('');
  const [currentPlaylistItemIndex, setCurrentPlaylistItemIndex] = useState(0);
  const [currentPlaylist, setCurrentPlaylist] = useState<Season>(null!);

  const prevPlaylist = computed<Season | undefined>(() => {
    const currentSeasonIndex = playlists.value.findIndex((playlist) => playlist.id === currentPlaylist.value.id);

    if (indexOutOfRange(currentSeasonIndex)) {
      return undefined;
    }

    return playlists.value[currentSeasonIndex - 1];
  });

  const nextPlaylist = computed<Season | undefined>(() => {
    const currentSeasonIndex = playlists.value.findIndex((playlist) => playlist.id === currentPlaylist.value.id);

    if (indexOutOfRange(currentSeasonIndex)) {
      return undefined;
    }

    return playlists.value[currentSeasonIndex + 1];
  });

  const currentPlaylistItem = computed<Episode | undefined>(
    () => currentPlaylist.value && currentPlaylist.value.episodes[currentPlaylistItemIndex.value],
  );

  const nextPlaylistItemInCurrentPlaylist = computed(
    () => currentPlaylist.value && currentPlaylist.value.episodes[currentPlaylistItemIndex.value + 1],
  );

  const prevPlaylistItemInCurrentPlaylist = computed(
    () => currentPlaylist.value && currentPlaylist.value.episodes[currentPlaylistItemIndex.value - 1],
  );

  const nextPlaylistItem = computed<Episode | undefined>(() => {
    if (nextPlaylistItemInCurrentPlaylist.value) {
      return nextPlaylistItemInCurrentPlaylist.value;
    }

    if (nextPlaylist.value) {
      return nextPlaylist.value.episodes[0];
    }

    return undefined;
  });

  const prevPlaylistItem = computed<Episode | undefined>(() => {
    if (prevPlaylistItemInCurrentPlaylist.value) {
      return prevPlaylistItemInCurrentPlaylist.value;
    }

    if (prevPlaylist.value) {
      return prevPlaylist.value.episodes[prevPlaylist.value.episodes.length - 1];
    }

    return undefined;
  });

  const setSafePlaylistItemIndex = (index: number) => {
    if (!currentPlaylist.value) {
      return;
    }

    if (index < 0) {
      currentPlaylistItemIndex.value = 0;
      return;
    }

    if (index > currentPlaylist.value.episodes.length - 1) {
      currentPlaylistItemIndex.value = currentPlaylist.value.episodes.length - 1;
      return;
    }

    currentPlaylistItemIndex.value = index;
  };

  const switchNextPlaylistItem = () => {
    setSafePlaylistItemIndex(currentPlaylistItemIndex.value + 1);
  };

  const switchPrevPlaylistItem = () => {
    setSafePlaylistItemIndex(currentPlaylistItemIndex.value - 1);
  };

  return {
    nextPlaylistItemInCurrentPlaylist,
    currentPlaylistItemIndex,
    currentPlaylistItemId,
    prevPlaylistItem,
    nextPlaylistItem,
    currentPlaylistItem,
    currentPlaylist,
    prevPlaylist,
    nextPlaylist,
    playlists,
    setCurrentPlaylistItemIndex,
    setPlaylists,
    switchNextPlaylistItem,
    switchPrevPlaylistItem,
    setCurrentPlaylist,
    setCurrentPlaylistItemId,
  };
});

export default usePlaylistStore;
