<template>
  <root-video-z-index-layer
    :class="{
      [$style.actionLabel]: true,
      [$style.actionLabelDisabled]: isDisabledLabelText,
    }"
    fullscreen
  >
    <app-label v-show="currentActionText" position="absolute" :top="top" :left="left" :text="currentActionText" />
    <div
      v-show="currentActionType"
      :class="{
        [$style.iconWrapper]: true,
        [$style.iconBackground]: isSmartTV,
        [$style.rewindButtonWrapper]: isRewindShown,
      }"
      :style="{
        left: $toPixel(iconPositionX),
        top: $toPixel(iconPositionY),
      }"
    >
      <icon-volume v-if="currentActionType === 'volume-on'" :size="isSmartTV ? 'extra-large' : 'large'" mode="full" />
      <icon-volume v-if="currentActionType === 'volume-off'" :size="isSmartTV ? 'extra-large' : 'large'" mode="off" />
      <icon-video-state v-if="currentActionType === 'play'" size="extra-large" mode="play" />
      <icon-video-state v-if="currentActionType === 'pause'" size="extra-large" mode="pause" />
      <template v-if="isSmartTV">
        <icon-skip-forward v-if="currentActionType === 'skip-forward'" size="large" />
        <icon-skip-backward v-if="currentActionType === 'skip-backward'" size="large" />
      </template>
      <div v-if="isWeb">
        <video-control-button
          v-if="currentActionType === 'skip-forward'"
          :on-click="() => {}"
          :is-background-shown="true"
          :is-rounded="true"
          text=""
          :class="$style.skipForwardButton"
        >
          <icon-skip-forward size="large" :skip-time="currentSkipTime" />
        </video-control-button>
        <video-control-button
          v-if="currentActionType === 'skip-backward'"
          :on-click="() => {}"
          :is-background-shown="true"
          :is-rounded="true"
          text=""
          :class="$style.skipBackwardButton"
        >
          <icon-skip-backward size="large" :skip-time="currentSkipTime" />
        </video-control-button>
      </div>
    </div>
  </root-video-z-index-layer>
</template>

<script lang="ts" setup>
import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import VideoControlButton from '@PLAYER/player/components/controls/VideoControlButton.vue';
import IconSkipBackward from '@PLAYER/player/components/icons/IconSkipBackward.vue';
import IconSkipForward from '@PLAYER/player/components/icons/IconSkipForward.vue';
import IconVideoState from '@PLAYER/player/components/icons/IconVideoState.vue';
import IconVolume from '@PLAYER/player/components/icons/IconVolume.vue';
import AppLabel from '@PLAYER/player/components/ui/AppLabel.vue';
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useRootVideoElBounds from '@PLAYER/player/modules/video/use-root-video-el-bounds';
import useVideoControlsVariables from '@PLAYER/player/modules/video/use-video-controls-variables';
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, ref, watch } from 'vue';

const videoControlsStore = useVideoControlsStore();
const { appWidth, appHeight } = useRootVideoElBounds();
const { isSmartTV, isWeb } = usePlatform();
const { currentActionType, isRewindShown } = useVideoControlsVariables();
const { currentAction, currentSkipTime, autoRepeatMultiplier } = storeToRefs(videoControlsStore);

let timeoutId: number;
const OFFSET_CENTER_PADDING = 25;

// Чтобы посередине торчало
const left = computed(() => appWidth.value / 2 - OFFSET_CENTER_PADDING);
// 4.5 чтобы уведомление ближе к верху
const top = computed(() => appHeight.value / (isSmartTV ? 50 : 10));
// Текущий текст
const currentActionText = computed(() => currentAction.value?.text || '');

// Позиция иконки по ординат
const iconPositionY = computed(() => appHeight.value / 2 - 36);
// Позиция иконки по абсциссе
const iconPositionX = computed(() => left.value - 10);

const isDisabledLabelText = ref(true);

watch(currentAction, (val, oldVal) => {
  isDisabledLabelText.value = false;

  if (timeoutId) {
    window.clearTimeout(timeoutId);
  }

  const timeout = val?.hideTimeout || ConstantsConfigPlayer.getProperty('currentActionViewHideTimeoutMs');

  timeoutId = window.setTimeout(() => {
    isDisabledLabelText.value = true;
  }, timeout);

  if (val?.type === 'skip-forward' || val?.type === 'skip-backward') {
    if (val?.type !== oldVal?.type) {
      videoControlsStore.setAutoRepeatMultiplier(0);
    }

    videoControlsStore.setAutoRepeatMultiplier((autoRepeatMultiplier.value += 1));
    videoControlsStore.setCurrentSkipTime(autoRepeatMultiplier.value * 10);
  }
});

onBeforeUnmount(() => {
  if (timeoutId) {
    window.clearTimeout(timeoutId);
  }
});
</script>

<style lang="scss" module>
.actionLabel {
  z-index: 2;
  min-width: 60px;
  opacity: 0.9;
  text-align: center;
  transition: opacity ease-in-out 0.5s;
}

.actionLabelDisabled {
  opacity: 0;
}

.iconWrapper {
  position: absolute;
  z-index: 2;
}

.rewindButtonWrapper {
  position: relative;
}

.iconBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background: var(--color-notheme-bg-secondary-80);
  color: var(--color-notheme-text-primary);
}

.skipBackwardButton,
.skipForwardButton {
  position: absolute;
  animation:
    action-show 200ms ease forwards,
    action-change-color 100ms linear forwards;
  animation-delay: 0ms, 210ms;
}

.skipBackwardButton {
  left: -20%;
}

.skipForwardButton {
  left: 20%;
}

@keyframes action-show {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--color-notheme-bg-hover-70);
  }
}

@keyframes action-change-color {
  from {
    background-color: var(--color-notheme-bg-hover-70);
  }
  to {
    background-color: var(--color-notheme-bg-tertiary-80);
  }
}
</style>
