<template></template>

<script lang="ts" setup>
import { VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import { useLoaderActions } from '@PLAYER/player/modules/hooks/use-loader-actions';
import useSafeGetMediaSourceTech from '@PLAYER/player/modules/hooks/use-safe-get-media-source-tech';
import type {
  MediaSourceTechEvent,
  MediaSourceTechEventError,
} from '@PLAYER/player/tech/events/media-source-tech-event';
import { ref } from 'vue';

const { releaseLoader } = useLoaderActions();
const getMediaSourceTech = useSafeGetMediaSourceTech();
const externalEventBus = useSafeExternalEventBus();

const isLoaderReleased = ref(false);

const safeReleaseLoader = () => {
  if (isLoaderReleased.value) {
    return;
  }

  releaseLoader();
  isLoaderReleased.value = true;
};

const onError = (event: MediaSourceTechEvent<MediaSourceTechEventError>) => {
  // MANIFEST_PARSING_ERROR can be called after MANIFEST_PARSED event,
  // because MANIFEST_PARSING_ERROR also triggered after chunklist parsing error
  const { errorType } = event.data;

  if (errorType === 'manifest-network-error' || errorType === 'manifest-parsing-error') {
    safeReleaseLoader();
  }
};

const onManifestParsed = () => {
  safeReleaseLoader();

  externalEventBus.emit('manifest-parsed', new VideoPlayerExternalEvent(undefined));
};

useEffect(() => {
  const mediaSourceTech = getMediaSourceTech.value;

  mediaSourceTech.on('error', onError);
  mediaSourceTech.on('manifest-parsed', onManifestParsed);

  return () => {
    mediaSourceTech.off('error', onError);
    mediaSourceTech.off('manifest-parsed', onManifestParsed);
  };
});
</script>
