<template>
  <app-icon :width="bounds.width" :height="bounds.height">
    <svg :viewBox="getSVGViewboxByIconSize(size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title v-if="$isNativeTitleTooltipShown">
        {{ mode === 'play' ? $t('controls.play') : $t('controls.pause') }}
      </title>
      <template v-if="size === 'extra-large'">
        <path
          v-if="mode === 'play'"
          d="M16.59 6.86852L39.9296 21.4558C41.8096 22.6308 41.8096 25.3688 39.9296 26.5438L16.59 41.131C14.5919 42.3799 12 40.9433 12 38.587V9.41251C12 7.05621 14.5918 5.61968 16.59 6.86852Z"
          fill="currentColor"
        />
        <path
          v-else
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13 6C11.3431 6 10 7.34315 10 9V39C10 40.6569 11.3431 42 13 42H15C16.6569 42 18 40.6569 18 39V9C18 7.34315 16.6569 6 15 6H13ZM33 6C31.3431 6 30 7.34315 30 9V39C30 40.6569 31.3431 42 33 42H35C36.6569 42 38 40.6569 38 39V9C38 7.34315 36.6569 6 35 6H33Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          v-if="mode === 'play'"
          d="M10.7512 3.86546L27.2835 14.1981C28.6151 15.0304 28.6151 16.9698 27.2835 17.8021L10.7512 28.1347C9.3359 29.0193 7.5 28.0018 7.5 26.3327V5.66746C7.5 3.99841 9.3359 2.98087 10.7512 3.86546Z"
          fill="currentColor"
        />
        <path
          v-else
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.20838 3.25C7.03477 3.25 6.08337 4.20139 6.08337 5.375V26.625C6.08337 27.7986 7.03477 28.75 8.20838 28.75H9.62505C10.7987 28.75 11.7501 27.7986 11.7501 26.625V5.375C11.7501 4.2014 10.7987 3.25 9.62505 3.25H8.20838ZM22.375 3.25C21.2014 3.25 20.25 4.20139 20.25 5.375V26.625C20.25 27.7986 21.2014 28.75 22.375 28.75H23.7917C24.9653 28.75 25.9167 27.7986 25.9167 26.625V5.375C25.9167 4.20139 24.9653 3.25 23.7917 3.25H22.375Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          v-if="mode === 'play'"
          d="M8.295 3.4345L19.9648 10.7281C20.9048 11.3156 20.9048 12.6846 19.9648 13.2721L8.295 20.5658C7.29593 21.1902 6 20.4719 6 19.2938V4.7065C6 3.52835 7.29592 2.81008 8.295 3.4345Z"
          fill="currentColor"
        />
        <path
          v-else
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.5 3C5.67157 3 5 3.67157 5 4.5V19.5C5 20.3284 5.67157 21 6.5 21H7.5C8.32843 21 9 20.3284 9 19.5V4.5C9 3.67157 8.32843 3 7.5 3H6.5ZM16.5 3C15.6716 3 15 3.67157 15 4.5V19.5C15 20.3284 15.6716 21 16.5 21H17.5C18.3284 21 19 20.3284 19 19.5V4.5C19 3.67157 18.3284 3 17.5 3H16.5Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          v-if="mode === 'play'"
          d="M5.22125 1.57563L13.9736 7.04585C14.6786 7.48648 14.6786 8.51322 13.9736 8.95385L5.22125 14.4241C4.47194 14.8924 3.5 14.3537 3.5 13.4701V2.52963C3.5 1.64602 4.47194 1.10732 5.22125 1.57563Z"
          fill="currentColor"
        />
        <path
          v-else
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.875 1.25C3.25368 1.25 2.75 1.75368 2.75 2.375V13.625C2.75 14.2463 3.25368 14.75 3.875 14.75H4.625C5.24632 14.75 5.75 14.2463 5.75 13.625V2.375C5.75 1.75368 5.24632 1.25 4.625 1.25H3.875ZM11.375 1.25C10.7537 1.25 10.25 1.75368 10.25 2.375V13.625C10.25 14.2463 10.7537 14.75 11.375 14.75H12.125C12.7463 14.75 13.25 14.2463 13.25 13.625V2.375C13.25 1.75368 12.7463 1.25 12.125 1.25H11.375Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'extra-small'">
        <path
          v-if="mode === 'play'"
          d="M4.1475 1.71725L9.9824 5.36407C10.4524 5.65782 10.4524 6.34231 9.9824 6.63606L4.1475 10.2829C3.64796 10.5951 3 10.236 3 9.64688V2.35325C3 1.76417 3.64796 1.40504 4.1475 1.71725Z"
          fill="currentColor"
        />
        <path
          v-else
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.25 1.5C2.83579 1.5 2.5 1.83579 2.5 2.25V9.75C2.5 10.1642 2.83579 10.5 3.25 10.5H3.75C4.16421 10.5 4.5 10.1642 4.5 9.75V2.25C4.5 1.83579 4.16421 1.5 3.75 1.5H3.25ZM8.25 1.5C7.83579 1.5 7.5 1.83579 7.5 2.25V9.75C7.5 10.1642 7.83579 10.5 8.25 10.5H8.75C9.16421 10.5 9.5 10.1642 9.5 9.75V2.25C9.5 1.83579 9.16421 1.5 8.75 1.5H8.25Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import {
  getBoundsByIconSize,
  getSVGViewboxByIconSize,
  type IconSize,
} from '@PLAYER/player/components/icons/icon-types';
import { computed } from 'vue';

import AppIcon from './AppIcon.vue';

const props = withDefaults(
  defineProps<{
    mode: 'play' | 'pause';
    size: IconSize;
  }>(),
  {},
);

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>
