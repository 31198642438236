import type Hls from 'hls.js';
import type { ErrorData, Events } from 'hls.js';

import { AbstractMediaAnalyzer, MediaAnalyzerEvent } from './abstract-media-analyzer';

export class HlsAnalyzer extends AbstractMediaAnalyzer {
  constructor(private readonly tech: Hls) {
    super();

    this.registerListeners();
  }

  private onError = (_: 'hlsError', data: ErrorData) => {
    const { type, details } = data;

    const isNetworkFragLoadError =
      type === 'networkError' && (details === 'fragLoadError' || details === 'levelLoadError');
    const isBufferedStalledError = details === 'bufferStalledError' || details === 'bufferNudgeOnStall';
    const isManifestParsingError = details === 'manifestParsingError';

    const isMediaError = type === 'mediaError';

    switch (true) {
      case isNetworkFragLoadError:
        return;
      case isBufferedStalledError:
        return this.emitter.emit('playback-interrupted', new MediaAnalyzerEvent('playback-interrupted'));
      case isManifestParsingError:
        return this.emitter.emit('playback-failed', new MediaAnalyzerEvent('playback-failed'));
      case isMediaError:
        return;
      default:
        // Что случилось дальше - мы не знаем, особо обработки на это нет, и кейсов очень много.
        // Но скорей всего, проигрывание уже не стартанет.
        // В связи с вышесказанным - считаем все другие ошибки критичными
        this.emitter.emit('playback-failed', new MediaAnalyzerEvent('playback-failed'));
    }
  };

  private registerListeners() {
    this.tech.on('hlsError' as Events.ERROR, this.onError);
  }

  public override dispose() {
    this.tech.off('hlsError' as Events.ERROR, this.onError);
  }
}
