import {
  type ContentAccessKind,
  MediaContentType,
  type Subscription,
  type TVChannelAccessKind,
} from '@package/sdk/src/api';
import type { OfferV2, PromoOfferV2 } from '@package/sdk/src/api/offers/offer-v2';
import { isDefined } from '@package/sdk/src/core';

interface EpisodeData {
  season?: number;
  episode?: number;
}

interface SubscribePromoTexts {
  subscribeButtonText?: string;
  subscribeButtonSubtext?: string;
}

interface GetContentPlayButtonTextOptions {
  isActiveSubscription: boolean;
  isAuth: boolean;
  isPartnerSubscription?: boolean;
  accessKind?: ContentAccessKind;
  offer: OfferV2;
  promoOffer?: PromoOfferV2;
  canContinueWatch: boolean;
  episodeData?: EpisodeData;
}

interface GetLivePlayButtonTextOptions {
  isActiveSubscription: boolean;
  isAuth: boolean;
  isPartnerSubscription?: boolean;
  accessKind?: TVChannelAccessKind;
  offer: OfferV2;
  subscribePromoTexts?: SubscribePromoTexts;
  hasTrialOffer?: boolean;
  promoOffer?: PromoOfferV2;
  subscription?: Subscription;
}

interface GetTVSliderButtonTextOptions {
  isAuth: boolean;
  accessKind?: TVChannelAccessKind;
}

interface GetMyChannelPlayButtonTextOptions {
  isForceSimpleWatchButtonText?: boolean;
  contentType?: MediaContentType;
}

interface GetFreeEpisodePopupButtonTextOptions {
  offer: OfferV2;
  promoOffer?: PromoOfferV2;
}

export interface TranslateArgs {
  key: string;
  params?: Record<string, string | number>;
}

export interface TPluralArgs {
  key: string;
  count?: number | string;
  params?: Record<string, string | number>;
}

export default function useContentMediaButtonTexts() {
  const DEFAULT_TRIAL_PRICE = 1;
  const DEFAULT_TRIAL_DURATION = 14;

  const passTranslateArgs = (key: string, params?: Record<string, string | number>) => {
    return { key, params };
  };

  const passTPluralArgs = (key: string, count: number, params?: Record<string, string | number>) => {
    return { key, count, params };
  };

  const getContentPlayButtonText = (options: GetContentPlayButtonTextOptions) => {
    const {
      isActiveSubscription,
      isPartnerSubscription,
      accessKind,
      offer,
      promoOffer,
      canContinueWatch,
      episodeData,
    } = options;

    if (!isPartnerSubscription && !isActiveSubscription && accessKind === 'all_users') {
      return passTranslateArgs('button.watchForFree');
    }

    if (isActiveSubscription) {
      if (canContinueWatch) {
        return passTranslateArgs('button.continueWatch');
      }

      if (episodeData?.episode && episodeData?.season) {
        // @ts-expect-error
        return passTranslateArgs('button.watchEpisode', episodeData);
      }

      return passTranslateArgs('button.watchMovie');
    }

    if (promoOffer?.watchingButtonTitlePromo) {
      return promoOffer?.watchingButtonTitlePromo;
    }

    if (offer?.watchingButtonTitle && !isPartnerSubscription) {
      return offer?.watchingButtonTitle;
    }

    return passTranslateArgs('button.watchDefault');
  };

  const getLivePlayButtonText = (options: GetLivePlayButtonTextOptions) => {
    const { isActiveSubscription, isAuth, isPartnerSubscription, accessKind, offer, promoOffer } = options;

    if (isActiveSubscription) {
      return passTranslateArgs('button.watchLive');
    }

    if (isAuth && !isActiveSubscription) {
      const canWatchContent = isDefined(accessKind) && (accessKind === 'register' || accessKind === 'all_users');

      if (canWatchContent || isPartnerSubscription) {
        return passTranslateArgs('button.watchLive');
      }
    }

    if (isDefined(accessKind) && accessKind === 'register') {
      return passTranslateArgs('button.registerToWatchChannel');
    }

    if (isDefined(accessKind) && accessKind === 'all_users') {
      return passTranslateArgs('button.watchLive');
    }

    if (promoOffer?.watchingButtonTitlePromo) {
      return promoOffer?.watchingButtonTitlePromo;
    }

    if (offer?.watchingButtonTitle) {
      return offer?.watchingButtonTitle;
    }

    return passTranslateArgs('button.watchDefault');
  };

  const getTVSliderButtonText = (options: GetTVSliderButtonTextOptions) => {
    const { accessKind, isAuth } = options;

    if (accessKind === 'all_users') {
      return passTranslateArgs('button.watchLive');
    }

    if (accessKind === 'register') {
      if (!isAuth) {
        return passTranslateArgs('button.registerToWatchChannel');
      }
    }

    return passTranslateArgs('button.watchLive');
  };

  const getMyChannelPlayButtonText = (options: GetMyChannelPlayButtonTextOptions) => {
    const { isForceSimpleWatchButtonText, contentType } = options;

    if (isForceSimpleWatchButtonText) {
      return passTranslateArgs('button.watch');
    }

    if (contentType) {
      return passTranslateArgs(contentType === MediaContentType.MOVIE ? 'button.watchMovie' : 'button.watchSerial');
    }

    return passTranslateArgs('button.watch');
  };

  const getFreeEpisodePopupButtonText = (options: GetFreeEpisodePopupButtonTextOptions) => {
    const { offer, promoOffer } = options;

    if (promoOffer?.watchingButtonTitlePromo) {
      return promoOffer?.watchingButtonTitlePromo;
    }

    if (offer?.watchingButtonTitle) {
      return offer?.watchingButtonTitle;
    }

    return passTranslateArgs('button.watchDefault');
  };

  return {
    getContentPlayButtonText,
    getLivePlayButtonText,
    getTVSliderButtonText,
    getMyChannelPlayButtonText,
    getFreeEpisodePopupButtonText,
    DEFAULT_TRIAL_DURATION,
    DEFAULT_TRIAL_PRICE,
    passTPluralArgs,
  };
}
