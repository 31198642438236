<template>
  <media-description-layer :title="title" :logo="logo" :content="content" />
  <slot></slot>
</template>

<script lang="ts" setup>
import type { Channel, Moment } from '@package/sdk/src/api';
import MediaDescriptionLayer from '@PLAYER/player/components/media-information/MediaDescriptionLayer.vue';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import { computed } from 'vue';

const { content } = useContentInformation();

const title = computed(() => (content.value as Moment)?.contentTitle);

const logo = computed(() => (content.value as Channel)?.logo);
</script>
