import { AvailableContentType, type Media } from '@package/sdk/src/api';

import { globalSettings } from '../services';
import { tPlural, translate } from '../services/localization/translate';

interface GetAvailabilityMessageOptions {
  isActiveSubscription?: boolean;
  withLicenseLockedInfo?: boolean;
  withHourOrMinute?: boolean;
  dateFormat?: string;
}

export function useSmartTVContentAvailability() {
  const daysLeft = (date: string): number => {
    const dayInMilliseconds = 1000 * 60 * 60 * 24;

    return Math.round((new Date(date).getTime() - new Date().setHours(0, 0, 0, 0)) / dayInMilliseconds);
  };

  const getUnavailableSoonText = (daysCount: number): string | string[] => {
    return tPlural('availability.unavailableSoon', daysCount, { days: daysCount });
  };

  const getAvailableSoonText = (date: string, time: string, withHourOrMinute: boolean): string | string[] => {
    if (withHourOrMinute) {
      return translate('pages.mediaCard.availability.availableSoonDateAndTime', { date, time });
    }

    return translate('pages.mediaCard.availability.availableSoonDate', { date });
  };

  const getAvailabilityMessage = (content?: Media, options: GetAvailabilityMessageOptions = {}): string | string[] => {
    const { isActiveSubscription, withHourOrMinute, withLicenseLockedInfo = true, dateFormat } = options;

    switch (content?.availability) {
      case AvailableContentType.UNAVAILABLE:
      case AvailableContentType.TimeshiftAvailable:
        return (
          (withLicenseLockedInfo && content.licenseLockedInfo) || translate('pages.mediaCard.availability.unavailable')
        );
      case AvailableContentType.UNAVAILABLESOON:
        return isActiveSubscription ? getUnavailableSoonText(daysLeft(content.licenseDateEnd as string)) : '';
      case AvailableContentType.AVAILABLESOON: {
        const licenseDateStart = globalSettings.dateFns.parseISO(content.licenseDateStart);

        const hour = globalSettings.dateFns.getHours(licenseDateStart);
        const minute = globalSettings.dateFns.getMinutes(licenseDateStart);
        const hasHourOrMinute = withHourOrMinute ? Boolean(hour || minute) : false;

        const date = globalSettings.dateFns.format(licenseDateStart, dateFormat || 'dd.MM.yyyy');
        const time = globalSettings.dateFns.format(licenseDateStart, 'H:mm');

        return getAvailableSoonText(date, time, hasHourOrMinute);
      }
      default:
        return '';
    }
  };

  return {
    getAvailabilityMessage,
  };
}
