<template>
  <NavigatableItem
    :tag="AppSlotButton"
    :class="$style.backButton"
    :active-class="$style.active"
    :focus-key="focusKey || FocusKeys.BACK_BUTTON"
    :autofocus="autofocus"
    :on-focus="onFocus"
    :on-blur="onBlur"
    @click="onBackButtonClick"
  >
    <ArrowLeftIcon :class="$style.backButtonIcon" />
  </NavigatableItem>
</template>

<script lang="ts" setup>
import { FocusKeys, routerService } from '@package/smarttv-base/src';
import ArrowLeftIcon from '@SMART/assets/icons/32x32/arrow-left.svg';

import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';

interface Props {
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  focusKey?: string;
  autofocus?: boolean;
}

const props = defineProps<Props>();

const onBackButtonClick = () => {
  if (props.onClick) {
    return props.onClick();
  }

  routerService.back();
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.backButton {
  position: absolute;
  top: adjust.adjustPx(80px);
  left: adjust.adjustPx(48px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: adjust.adjustPx(80px);
  height: adjust.adjustPx(80px);
  border-radius: 50%;
  background-color: rgba(63, 80, 86, 1);

  &Icon {
    width: adjust.adjustPx(40px);
    height: adjust.adjustPx(40px);
    color: var(--color-icon-primary);
  }
}

.active {
  background-color: var(--color-bg-accent);

  .backButtonIcon {
    color: var(--color-notheme-icon-accent);
  }
}
</style>
