<template>
  <section ref="el" :class="$style.keyboard">
    <slot name="input" :type="inputType" :input="text" :placeholder="placeholder">
      <div :class="$style.input">
        <virtual-keyboard-microphone
          v-if="isSpeechRecordingFeatureAvailable"
          :key="countValue"
          @voice:recording:start="emit('voice:recording:start')"
          @voice:recording:stop="onRecordingStop"
          @active="emit('active')"
        />
        <UIInput
          v-model:input="text"
          :class="{
            [$style.keyboardInput]: true,
            [$style.secondary]: inputVariant === 'secondary',
          }"
          :placeholder="placeholder"
          :type="inputType"
          :borderless="!text"
          :input-mask="inputMask"
          disabled
        />
      </div>
    </slot>

    <div v-if="props.errorMessage" :class="[$style.error, errorClass]">
      {{ props.errorMessage }}
    </div>

    <transition :name="transitionName">
      <div :class="$style.keys">
        <div :class="$style.suggestions">
          <template v-if="suggestions">
            <NavigatableItem
              v-for="suggestion in suggestions"
              :key="suggestion"
              :tag="UIButton"
              :class="$style.suggestionsItem"
              @click="onAppend(suggestion)"
              @active="emit('active')"
            >
              {{ suggestion }}
            </NavigatableItem>
          </template>
        </div>

        <CustomKeyboard
          v-show="!isKeyboardHidden"
          :items="currentKeyboard"
          :upper-case="upperCase"
          :symbols-upper-case="symbolsUpperCase"
          :item-width="props.itemWidth"
          @key:common="onInput"
          @key:control="onControlKey"
          @active="emit('active')"
        />
      </div>
    </transition>
  </section>
</template>

<script setup lang="ts">
import { FeatureToggle } from '@package/sdk/src/api';
import { debounce, isString } from '@package/sdk/src/core';
import useTransitionName from '@package/smarttv-base/src/utils/use-transition-name';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { deviceService, featuresService, FocusKeys } from '@SMART/index';
import { useCounter } from '@vueuse/core';
import { computed, nextTick, provide, ref } from 'vue';

import UIButton from '@/components/button/UIButton.vue';
import UIInput from '@/components/input/UIInput.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import CustomKeyboard from '@/components/virtual-keyboard/CustomKeyboard.vue';
import VirtualKeyboardMicrophone from '@/components/virtual-keyboard/VirtualKeyboardMicrophone.vue';

import type { Keyboard, KeyboardItem } from './keyboards';

interface Props {
  keyboards: Keyboard[];
  input: string;
  debounceMs?: number;
  upperCase?: boolean;
  symbolsUpperCase?: boolean;
  placeholder?: string;
  isKeyboardHidden?: boolean;
  itemWidth?: number;
  suggestions?: string[];
  inputType?: string;
  onConcatSuggestion?: (input: string, suggestion: string) => string;
  inputMask?: string;
  inputVariant?: 'secondary';
  maxLength?: number;
  errorMessage?: string;
  errorClass?: string;
}

const emit = defineEmits<{
  (e: 'update:input', value: string): void;
  (e: 'patch:input', value: string): void;
  (e: 'active'): void;
  (e: 'voice:recording:start'): void;
  (e: 'voice:recording:stop', value: string): void;
}>();

const props = withDefaults(defineProps<Props>(), {
  upperCase: false,
  inputType: 'text',
  isKeyboardHidden: false,
  inputMask: '',
});

const { el, focusKey } = useNavigatable({
  focusKey: FocusKeys.VIRTUAL_KEYBOARD,
  trackChildren: true,
  updateLayoutOnFocus: true,
  saveLastFocusedChild: true,
});
provide('parentFocusKey', focusKey.value);

const { transitionName } = useTransitionName();

const { count: countValue, inc: incrementCounter } = useCounter();

const onRecordingStop = (value: string) => {
  text.value = value;
  incrementCounter();
  emit('voice:recording:stop', value);
};

const isSpeechRecordingFeatureAvailable = computed(
  () =>
    featuresService.getFeatureFlag(FeatureToggle.SmartTvSpeechApiEnabled)?.status &&
    deviceService.getFeatureState('speechRecognitionApi'),
);

const upperCase = ref(props.upperCase);

const resolvedKeyboards = computed(() => {
  return props.keyboards.map((keyboard: Keyboard) => {
    return {
      type: keyboard.type,
      keys: keyboard.keys.map((row) => row.map((key: KeyboardItem | string) => (isString(key) ? { key } : key))),
    };
  });
});

const text = computed<string>({
  get: () => props.input,
  set: (value) => emit('update:input', value),
});

const onInputRaw = (key: string) => {
  const value = props.input + key;

  emit('update:input', props.maxLength ? value.slice(0, props.maxLength) : value);
  emit('patch:input', key);
};

const onInput = props?.debounceMs ? debounce(onInputRaw, props.debounceMs) : onInputRaw;

const onControlKeyRaw = (item: KeyboardItem) => {
  item.callback && item.callback();
};

const onControlKey = props?.debounceMs ? debounce(onControlKeyRaw, props.debounceMs) : onControlKeyRaw;

const onAppend = (value: string) => {
  const { onConcatSuggestion: concat } = props;

  const input = concat ? concat(props.input, value) : props.input + value;

  emit('update:input', input);
};

const type = ref(resolvedKeyboards.value[0].type);

const currentKeyboard = computed(() => {
  return (
    resolvedKeyboards.value.find((keyboard) => keyboard.type === type.value)?.keys ?? resolvedKeyboards.value[0].keys
  );
});

const doActionWithPositionSave = async (callback: Function, preferredFocusKey?: string) => {
  const nextFocusKey = preferredFocusKey || SpatialNavigation.getCurrentFocusKey();

  callback();
  await nextTick();

  SpatialNavigation.setFocus(nextFocusKey);
};

const changeType = async (newType: string) => {
  doActionWithPositionSave(() => {
    type.value = newType;
  });
};

const setUpperCase = async (value: boolean) => {
  doActionWithPositionSave(() => {
    upperCase.value = value;
  });
};

defineExpose({
  changeType,
  type,

  setUpperCase,
  upperCase,
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

@mixin item($width) {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: adjust.adjustPx(3px);
  padding: adjust.adjustPx(5px) adjust.adjustPx(14px);
  min-width: adjust.adjustPx($width);
  max-width: adjust.adjustPx($width);
  min-height: adjust.adjustPx(68px);
  max-height: adjust.adjustPx(68px);
  border: adjust.adjustPx(3px) solid var(--c-keyboard-color);
  border-radius: adjust.adjustPx(6px);
  background: var(--c-keyboard-color);
}

.input {
  display: flex;
}

.keyboard {
  &Input {
    width: 100%;
  }

  .secondary {
    padding-left: adjust.adjustPx(24px);
    border-radius: adjust.adjustPx(10px);
    background: var(--color-bg-field);
  }

  &Layout {
    display: flex;
    flex-flow: row;
    width: adjust.adjustPx(840px);
    height: adjust.adjustPx(290px);
  }
}

.error {
  margin-top: adjust.adjustPx(8px);
  color: var(--color-text-negative);

  @include smartTvFonts.SmartTvCaption-1();
}

.keys {
  margin-top: adjust.adjustPx(32px);
  width: min-content;
}

.suggestions {
  display: flex;

  &Item {
    @include item(222px);
  }

  &Item:last-child {
    @include item(145px);
  }
}
</style>
