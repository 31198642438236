import useVideoChapters from '@PLAYER/player/modules/content/use-video-chapters';
import type { MediaChapter } from '@PLAYER/player/modules/interfaces/chapters';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { ulid } from 'ulid';
import { computed } from 'vue';

export type TimelineChapter = MediaChapter;

export default function useTimelineChapters() {
  const { chapters, isChaptersPresent } = useVideoChapters();
  const { normalizedDuration } = useVideoPlayerVariables();

  const generateLastEmptyChapter = (start: number, duration: number): TimelineChapter => {
    return {
      id: ulid(),
      startOffset: start,
      endOffset: duration,
      title: '',
    };
  };

  const timelineChapters = computed<MediaChapter[]>(() => {
    const result: MediaChapter[] = [];
    const items = chapters.value;

    if (!isChaptersPresent.value) {
      return [];
    }

    for (let i = 0; i < items.length; i++) {
      const current = items[i];
      const next = items[i + 1];

      result.push({
        id: current.id,
        startOffset: current.startOffset,
        endOffset: current.endOffset,
        title: current.title,
      });

      if (!next) {
        result.push(generateLastEmptyChapter(current.endOffset, normalizedDuration.value + 1));
      }
    }

    return result.sort((a, b) => (a.startOffset > b.startOffset ? 1 : -1));
  });

  return {
    timelineChapters,
  };
}
