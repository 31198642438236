<template>
  <div
    :class="{
      [$style.timelineNextContent]: true,
      [$style.timelineNextContentSmartTv]: isSmartTV,
    }"
  >
    <app-player-button
      ref="el"
      :class="{ [$style.button]: true, [$style.smartTvActive]: isSmartTV && focused }"
      :on-click="onWatchTitles"
    >
      {{ $t('vod.nextMedia.watchTitles') }}
    </app-player-button>
    <next-episode-preview-element
      :episode-title="episodeTitle"
      :on-request-episode="onRequestEpisode"
      :preview-src="previewSrc"
      :is-button-animated="isButtonAnimated && !isSmartTV"
    />
  </div>
</template>

<script lang="ts" setup>
import type { AnyFunction } from '@PLAYER/player/base/function';
import NextEpisodePreviewElement from '@PLAYER/player/components/media-information/NextEpisodePreviewElement.vue';
import AppPlayerButton from '@PLAYER/player/components/ui/AppPlayerButton.vue';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useSmartNavigatable from '@PLAYER/player/versions/smart/modules/smart-navigation/use-smarttv-navigatable';

const props = defineProps<{
  episodeTitle: string;
  previewSrc: string;
  onRequestEpisode: VoidFunction;
  onWatchTitles: AnyFunction;
  isButtonAnimated: boolean;
}>();

const { isSmartTV } = usePlatform();

const { useNavigatable } = useSmartNavigatable();

const { el, focused } = useNavigatable({ onEnterPress: props.onWatchTitles });
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.timelineNextContent {
  display: flex;
}

.timelineNextContentSmartTv {
  .button {
    order: 1;
    margin-left: var(--g-spacing-8);
  }
}

.button {
  order: 0;
  margin-right: var(--g-spacing-8);
  padding: var(--g-spacing-16);
  min-height: 52px;
}

.smartTvActive {
  border: adjust.adjustPx(8px) solid var(--color-notheme-bg-accent);
}
</style>
