import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import { inject, type Ref } from 'vue';

const injectKey = 'app.video.session.counter';

export default function useSafeCurrentVideoSessionCounter() {
  const { getDefault } = useDefaultProvide();

  const currentVideoSessionCounter = inject<Ref<number>>(injectKey, getDefault(injectKey));

  if (!currentVideoSessionCounter) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return currentVideoSessionCounter;
}
