<template>
  <navigatable-item
    :on-click="onClickStartRecordingSpeech"
    :class="{
      [$style.microphone]: true,
      [$style.microphoneActive]: isListeningRecording,
    }"
    :tag="AppButton"
    :on-blur="onBlur"
    :focus-key="FocusKeys.KEYBOARD_KEY(FocusKeys.VOICE_SEARCH)"
    @active="emit('active')"
  >
    <template #icon>
      <audio-icon />
    </template>
  </navigatable-item>
</template>

<script setup lang="ts">
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import { FocusKeys } from '@package/smarttv-base/src';
import AudioIcon from '@SMART/assets/icons/51x51/audio.svg';
import { useSpeechRecognition } from '@vueuse/core';
import { ref, watch } from 'vue';

import AppButton from '@/components/app-button/AppButton.vue';

const {
  isFinal: isFinalRecording,
  isListening: isListeningRecording,
  start: onStartRecordingSpeech,
  stop: onStopRecordingSpeech,
  result: speechResult,
} = useSpeechRecognition({
  lang: 'ru-RU',
  continuous: false,
});

let speechTimeoutId: number;

const emit = defineEmits<{
  (e: 'active'): void;
  (e: 'voice:recording:start'): void;
  (e: 'voice:recording:stop', value: string): void;
}>();

const text = ref('');

watch(isFinalRecording, (val) => {
  if (!val) {
    return;
  }

  if (speechTimeoutId) {
    window.clearTimeout(speechTimeoutId);
  }

  if (speechResult.value) {
    text.value = speechResult.value;
    emit('voice:recording:stop', text.value);
  }
});

const onBlur = () => {
  if (speechTimeoutId) {
    window.clearTimeout(speechTimeoutId);
  }

  onStopRecordingSpeech();
};

const onClickStartRecordingSpeech = () => {
  if (isListeningRecording.value) {
    if (speechTimeoutId) {
      window.clearTimeout(speechTimeoutId);
    }
    return onStopRecordingSpeech();
  }

  emit('voice:recording:start');
  onStartRecordingSpeech();

  speechTimeoutId = window.setTimeout(() => {
    emit('voice:recording:stop', text.value);
    onStopRecordingSpeech();
  }, ConstantsConfigInstanceSmartTV.getProperty('voiceSearchStopTimeoutMs'));
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.microphone {
  margin-right: adjust.adjustPx(20px);
  width: adjust.adjustPx(96px);
  height: adjust.adjustPx(96px);

  svg {
    width: adjust.adjustPx(48px);
    height: adjust.adjustPx(48px);
  }

  &Active {
    // !important need for focussed state button
    background-color: var(--color-bg-negative) !important;
    color: var(--color-notheme-icon-accent) !important;

    svg {
      animation-name: zoom;
      animation-duration: 1200ms;
      animation-timing-function: ease-in;
      animation-iteration-count: infinite;
    }
  }
}
</style>
