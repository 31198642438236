<template>
  <playback-time-view
    :is-current-time-shown="isCurrentTimeShown"
    :is-duration-shown="false"
    :current-time-css-class-name="currentTimeCssClassName"
    :duration-css-class-name="durationCssClassName"
    :current-time="normalizedTime"
    :duration="normalizedDuration"
  />
</template>

<script lang="ts" setup>
import { getTimeInSeconds } from '@PLAYER/player/base/date';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import PlaybackTimeView from './PlaybackTimeView.vue';

const props = defineProps<{
  durationCssClassName?: string;
  currentTimeCssClassName?: string;
  isCurrentTimeLive?: boolean;
}>();

const { customManifestTotalDuration } = storeToRefs(useManifestStore());
const { isLiveWithDVR, normalizedDuration, normalizedDisplayedCurrentTime, isCustomMediaTech } =
  useVideoPlayerVariables();

const { isSmartTV } = usePlatform();

const normalizedTime = computed(() => {
  if (normalizedDisplayedCurrentTime.value < 0) {
    return 0;
  }

  let time: number;

  if (isLiveWithDVR.value && isCustomMediaTech.value) {
    const currentTimeInSeconds = getTimeInSeconds(new Date());

    if (props.isCurrentTimeLive) {
      time = currentTimeInSeconds;
    } else {
      const timeshiftDeltaInSeconds = customManifestTotalDuration.value - normalizedDisplayedCurrentTime.value;
      time = Math.trunc(currentTimeInSeconds - timeshiftDeltaInSeconds);
    }
  } else {
    time = normalizedDisplayedCurrentTime.value - normalizedDuration.value;
  }

  return Math.abs(time);
});

const isCurrentTimeShown = computed(() => {
  return true;
});
</script>
