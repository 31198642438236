<template>
  <nav
    ref="el"
    :class="{ [$style.menu]: true, [$style.active]: hasFocusedChild }"
    @mouseover="setMenuState(true)"
    @mouseleave="setMenuState(false)"
  >
    <ul :class="{ [$style.list]: true, [$style.active]: hasFocusedChild }" role="list">
      <li v-if="isBackMenuShown && false">
        <NavigatableItem
          :class="$style.item"
          :active-class="$style.active"
          :tag="AppSlotButton"
          :extra-props="extraProps"
          data-testid="nav-0"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
          @click="onRouterBack"
        >
          <span :class="[$style.icon, $style.itemIconBack]">
            <ArrowUpIcon />
          </span>

          <span :class="$style.text">{{ $t('appMenu.back') }}</span>
        </NavigatableItem>
      </li>
      <li v-if="_user && _profile && isActiveSubscription" :class="$style.profile">
        <NavigatableItem
          :class="{
            [$style.item]: true,
            [$style.profile]: true,
            [$style.profileChild]: _profile.kind === ProfileType.CHILD && !isMenuActive,
          }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-1"
          :extra-props="extraProps"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
          @click="onClickProfileSection()"
        >
          <span :class="$style.icon">
            <ProfileAdultIcon v-if="_profile.kind !== ProfileType.CHILD" />
            <ProfileChildIcon v-else />
          </span>

          <span :class="$style.text">{{ _profile.name }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.located]: $route.name === $RouterPage.MainPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-2"
          :extra-props="extraProps"
          @click="onClickMenuSection($RouterPage.MainPage)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <IconMain />
          </span>

          <span :class="$style.text">{{ $t('appMenu.main') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.located]: $route.name === $RouterPage.SearchPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-3"
          :extra-props="extraProps"
          @click="onClickMenuSection($RouterPage.SearchPage)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <IconSearch />
          </span>

          <span :class="$style.text">{{ $t('appMenu.search') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.located]: $route.name === $RouterPage.CatalogPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-4"
          :extra-props="extraProps"
          @click="onClickMenuSection($RouterPage.CatalogPage)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <IconCatalog />
          </span>

          <span :class="$style.text">{{ $t('appMenu.catalog') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.located]: $route.name === $RouterPage.CollectionPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-5"
          :extra-props="extraProps"
          @click="onClickMenuSection($RouterPage.CollectionPage)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <IconSave />
          </span>

          <span :class="$style.text">{{ $t('appMenu.collection') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.located]: $route.name === $RouterPage.ChannelsPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-6"
          :extra-props="extraProps"
          @click="onClickMenuSection($RouterPage.ChannelsPage)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <IconTv />
          </span>

          <span :class="$style.text">{{ $t('appMenu.tv') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.located]: $route.name === $RouterPage.MyChannelPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-7"
          :extra-props="extraProps"
          @click="onClickMenuSection($RouterPage.MyChannelPage)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <IconKinom />
          </span>

          <span :class="$style.text">{{ $t('appMenu.myChannel') }}</span>
        </NavigatableItem>
      </li>
      <li v-if="_user">
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.located]: isSettingsItemActive }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-8"
          :extra-props="extraProps"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
          @click="onClickSettingsSection"
        >
          <span :class="$style.icon">
            <IconSettings />
          </span>

          <span :class="$style.text">{{ $t('appMenu.settings') }}</span>
        </NavigatableItem>
      </li>
      <li v-if="!isRelease">
        <NavigatableItem
          :class="$style.item"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-9"
          :extra-props="extraProps"
          @click="onClickMenuSection($RouterPage.LogInfo)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <InfoIcon />
          </span>

          <span :class="$style.text">{{ $t('appMenu.log') }}</span>
        </NavigatableItem>
      </li>
      <li v-if="!_user">
        <NavigatableItem
          :class="[$style.item, $style.exitButton, { [$style.exitButtonOpen]: hasFocusedChild }]"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-10"
          :extra-props="extraProps"
          @click="onClickMenuSection($RouterPage.AuthPage)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <div :class="$style.exitButtonContainer">
            <span :class="{ [$style.icon]: true, [$style.iconLogin]: true }">
              <AuthIcon />
            </span>

            <span :class="$style.exitButtonText">{{ $t('appMenu.login') }}</span>
          </div>
        </NavigatableItem>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import {
  useAccountPageAnalytics,
  useCatalogPageAnalytics,
  useMainPageAnalytics,
  useMyChannelAnalytics,
  useMyCollectionPageAnalytics,
  useSearchPageAnalytics,
  useTvPageAnalytics,
} from '@package/sdk/src/analytics';
import { ProfileType } from '@package/sdk/src/api';
import type { AnyFunction } from '@package/sdk/src/core/structures/common';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import AuthIcon from '@SMART/assets/icons/33x33/auth.svg';
import InfoIcon from '@SMART/assets/icons/33x33/info.svg';
import IconCatalog from '@SMART/assets/icons/40x40/catalog.svg';
import IconKinom from '@SMART/assets/icons/40x40/kinom.svg';
import IconMain from '@SMART/assets/icons/40x40/main.svg';
import ProfileAdultIcon from '@SMART/assets/icons/40x40/profile-adult.svg';
import ProfileChildIcon from '@SMART/assets/icons/40x40/profile-child.svg';
import IconSave from '@SMART/assets/icons/40x40/save.svg';
import IconSearch from '@SMART/assets/icons/40x40/search.svg';
import IconSettings from '@SMART/assets/icons/40x40/settings.svg';
import IconTv from '@SMART/assets/icons/40x40/tv.svg';
import ArrowUpIcon from '@SMART/assets/icons/51x51/arrow-up.svg';
import type { SessionGetters, SessionState } from '@SMART/index';
import {
  analyticService,
  AppKeyboardEvent,
  environmentService,
  FocusKeys,
  RouterPage,
  routerService,
  storeToRefs,
  useAuthActions,
  useAuthStore,
  useMouseDetect,
  useSessionStore,
} from '@SMART/index';
import { computed, provide, ref } from 'vue';
import { useRoute } from 'vue-router';

import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';

const isMenuActive = ref(false);
const isRelease = environmentService.getVariable('isRelease');

const route = useRoute();
const { isMouseDetected } = useMouseDetect();
const { _profile, _user, isActiveSubscription } = storeToRefs<SessionState, SessionGetters, unknown>(useSessionStore());
const authStore = useAuthStore();
const { openAuthPage } = useAuthActions();

const mainPageAnalytics = useMainPageAnalytics(analyticService.sender);
const tvPageAnalytics = useTvPageAnalytics(analyticService.sender);
const mychannelAnalytics = useMyChannelAnalytics(analyticService.sender);
const searchAnalytics = useSearchPageAnalytics(analyticService.sender);
const myCollectionAnalytics = useMyCollectionPageAnalytics(analyticService.sender);
const catalogPageAnalytics = useCatalogPageAnalytics(analyticService.sender);
const accountPageAnalytics = useAccountPageAnalytics(analyticService.sender);

const { el, hasFocusedChild, focusKey } = useNavigatable({
  focusKey: FocusKeys.APP_MENU,
  trackChildren: true,
  updateLayoutOnFocus: false,
  autoRestoreFocus: false,
  saveLastFocusedChild: true,
  isFocusBoundary: true,
  focusBoundaryDirections: ['left', 'down', 'up'],
  hasGlobalAccess: true,
});
provide('parentFocusKey', focusKey.value);
provide('updateLayoutOnFocus', true);

const extraProps = {
  disableMyChannelModalWhenNavigate: true,
};

const setMenuState = (isOpen: boolean) => {
  isMenuActive.value = isOpen;
};

const onRouterBack = (event: KeyboardEvent) => routerService.onBackspace(new AppKeyboardEvent(event));

type AppMenuRouterName =
  | RouterPage.LogInfo
  | RouterPage.MainPage
  | RouterPage.ChannelsPage
  | RouterPage.MyChannelPage
  | RouterPage.SearchPage
  | RouterPage.CollectionPage
  | RouterPage.CatalogPage
  | RouterPage.AuthPage;

const gotoAnalyticEventDict: Record<AppMenuRouterName, AnyFunction> = {
  [RouterPage.LogInfo]: () => ({}),
  [RouterPage.MainPage]: () => mainPageAnalytics.onGotoMainPage(),
  [RouterPage.ChannelsPage]: () => tvPageAnalytics.onGotoTvChannelsPage(),
  [RouterPage.MyChannelPage]: () => mychannelAnalytics.onGotoMyChannelPage(),
  [RouterPage.SearchPage]: () =>
    searchAnalytics.onGotoSearchPage({
      request: '',
      from: '',
      page: analyticService.getAnalyticPageName(),
    }),
  [RouterPage.CollectionPage]: () => myCollectionAnalytics.onGotoCollectionsPage(analyticService.getAnalyticPageName()),
  [RouterPage.CatalogPage]: () => catalogPageAnalytics.onGotoCatalogPage(),
  [RouterPage.AuthPage]: () => {
    accountPageAnalytics.onClickAuthLoginTv();
    openAuthPage('sign_in');
  },
};

const onClickMenuSection = (name: AppMenuRouterName) => {
  const handler = gotoAnalyticEventDict[name];

  if (handler) {
    Reflect.apply(handler, undefined, []);
  }

  return routerService.push({ name });
};

const onClickProfileSection = () => {
  const isParentalOrProfilePage = [
    RouterPage.ProfilesPage,
    RouterPage.ParentalPage,
    RouterPage.ParentalCodePage,
    RouterPage.ParentalCodeRecoverPage,
  ].includes(route.name as RouterPage);

  if (!isParentalOrProfilePage) {
    authStore.setBackUrl(route.fullPath);
  }

  accountPageAnalytics.onGotoProfilesPage(analyticService.getAnalyticPageName());

  return routerService.push({ name: RouterPage.ProfilesPage });
};

const onClickSettingsSection = () => {
  if (!_user?.value) {
    return openAuthPage('sign_in');
  }

  accountPageAnalytics.onGotoAccountPage(analyticService.getAnalyticPageName());

  return routerService.push({ name: RouterPage.SettingsPage });
};

const isSettingsItemActive = computed(() =>
  [RouterPage.PersonalData, RouterPage.ParentalControl, RouterPage.HelpContacts, RouterPage.UserSubscriptions].includes(
    route.name as AppMenuRouterName,
  ),
);

const isBackMenuShown = computed(() => !isRelease && isMouseDetected.value && window.history.length > 2);

defineExpose({
  hasFocusedChild,
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px' as adjust;

@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

html:global(.has-backdropFilter) {
  .menu {
    backdrop-filter: blur(10px);
    background-color: transparent;
    opacity: 1;
  }
}

.menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: adjust.adjustPx(-500px);
  z-index: map-get($map: $layers, $key: --z-index-nav);
  transform: translateX(adjust.adjustPx(500px));
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-bg-primary);
  opacity: 0.96;
}

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: adjust.adjustPx(80px);
  opacity: 1;
  overflow: hidden;
  transition: 0.2s;
  margin: {
    top: adjust.adjustPx(48px);
    bottom: adjust.adjustPx(60px);
    left: adjust.adjustPx(24px);
    right: adjust.adjustPx(24px);
  }

  li {
    margin-bottom: adjust.adjustPx(23px);

    &.profile {
      margin-bottom: adjust.adjustPx(20px);
    }
  }

  &.active {
    min-width: adjust.adjustPx(420px);
    margin: {
      top: adjust.adjustPx(48px);
      bottom: adjust.adjustPx(60px);
      left: adjust.adjustPx(40px);
      right: adjust.adjustPx(40px);
    }
    transition: 0.2s;
  }
}

.item {
  display: flex;
  align-items: center;
  min-height: adjust.adjustPx(80px);
  border-radius: adjust.adjustPx(16px);
  outline: none;
  text-decoration: none;
  color: var(--color-text-primary);
  white-space: nowrap;

  @include smartTvFonts.SmartTvLabel-2();

  &.located {
    background-color: var(--color-stroke-field-border);
  }

  &.active {
    background-color: var(--color-notheme-bg-accent);
    color: var(--color-notheme-text-accent);
  }

  &.profile {
    margin-bottom: adjust.adjustPx(15px);

    &:after {
      content: '';
    }

    &Child {
      background: linear-gradient(
        90deg,
        rgba(70, 177, 171, 1) 0%,
        rgba(70, 126, 177, 1) 0.01%,
        rgba(157, 98, 193, 1) 100%
      );
    }
  }

  &:hover,
  &:focus {
    background-color: var(--color-bg-accent);
    text-decoration: none;
    color: var(--color-notheme-text-accent);
  }

  svg {
    width: adjust.adjustPx(40px);
    height: adjust.adjustPx(40px);
  }
}

.exitButton {
  position: absolute;
  bottom: 0;
  background-color: var(--color-stroke-field-border);

  &Open {
    .exitButtonText {
      display: flex;
    }

    .iconLogin {
      margin-right: adjust.adjustPx(16px);
      width: adjust.adjustPx(32px);
      height: adjust.adjustPx(32px);
    }
  }
}

.exitButtonContainer {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.exitButtonText {
  display: none;
  align-items: center;
}

.itemIconBack {
  transform: rotate(-90deg);
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 0;
  min-width: adjust.adjustPx(81px);

  &Login {
    align-self: center;
    width: adjust.adjustPx(40px);
    min-width: initial;
    height: adjust.adjustPx(40px);
  }

  &Profile {
    background-color: var(--color-stroke-field-border);
  }

  &Child {
    background: linear-gradient(
      90deg,
      rgba(70, 177, 171, 1) 0%,
      rgba(70, 126, 177, 1) 0.01%,
      rgba(157, 98, 193, 1) 100%
    );
  }

  &Profile,
  &Child {
    margin-left: adjust.adjustPx(7px);
    min-width: adjust.adjustPx(67px);
    min-height: adjust.adjustPx(67px);
    border-radius: 50%;
  }
}

.text {
  flex: 1;

  @include smartTvFonts.SmartTvBody-2();
}
</style>
