import { TvKeyCode } from '@package/sdk/src/core';
import getSmartTVKeyWithPlatform from '@PLAYER/player/versions/smart/modules/keyboard/get-smarttv-key-with-platform';

export const SmartTvCode = {
  Play: getSmartTVKeyWithPlatform(TvKeyCode.PLAY),
  Pause: getSmartTVKeyWithPlatform(TvKeyCode.PAUSE),
  Return: getSmartTVKeyWithPlatform(TvKeyCode.RETURN),
  Right: getSmartTVKeyWithPlatform(TvKeyCode.RIGHT),
  Left: getSmartTVKeyWithPlatform(TvKeyCode.LEFT),
  Next: getSmartTVKeyWithPlatform(TvKeyCode.CHANNEL_DOWN),
  Forward: getSmartTVKeyWithPlatform(TvKeyCode.FORWARD),
  Rewind: getSmartTVKeyWithPlatform(TvKeyCode.REWIND),
  Stop: getSmartTVKeyWithPlatform(TvKeyCode.STOP),
  Prev: getSmartTVKeyWithPlatform(TvKeyCode.CHANNEL_UP),
  Enter: getSmartTVKeyWithPlatform(TvKeyCode.ENTER),
};
