<template>
  <section :class="$style.wrapper">
    <section :class="$style.stub">
      <div><slot name="icon"></slot></div>
      <h3 :class="$style.text"><slot name="text"></slot></h3>
    </section>
  </section>
</template>

<script setup lang="ts"></script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-notheme-bg-primary);
}

.stub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: adjustPx(540px);
  height: adjustPx(160px);

  svg {
    width: adjustPx(80px);
    height: adjustPx(80px);
    fill: var(--color-notheme-text-primary);
  }

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include f-label-2;
  }
}
</style>
